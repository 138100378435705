import React, { useEffect, useState } from 'react'
import { BsFillStarFill } from 'react-icons/bs'
import DatePicker from "react-datepicker";

const LeftPanel = (props) => {

    const { reviewData, filterData, setFilterData } = props

    const [state, setState] = useState({
        averageRating: 0,
        fiveCount: 0,
        fourCount: 0,
        threeCount: 0,
        twoCount: 0,
        oneCount: 0,
    })

    const updateStateWithReviewData = (reviewData) => {
        const counts = {
            fiveCount: 0,
            fourCount: 0,
            threeCount: 0,
            twoCount: 0,
            oneCount: 0,
        };

        reviewData.forEach(review => {
            const rating = review.data.starRating;
            counts[rating.toLowerCase() + 'Count']++;
        });

        setState({
            averageRating: calculateAverageRating(reviewData),
            ...counts
        });
    };

    const ratingValue = (rating) => {
        return {
            "FIVE": 5,
            "FOUR": 4,
            "THREE": 3,
            "TWO": 2,
            "ONE": 1
        }[rating];
    };

    const calculateAverageRating = (reviewData) => {
        const totalRating = reviewData.reduce((acc, review) => acc + ratingValue(review.data.starRating), 0);
        return totalRating / reviewData.length || 0;
    };
    const handleClearDate = () => {
        setFilterData({ ...filterData, fromDate: "", toDate: "" })
    };

    const handleChange = (e, name) => {
        if (name === "ratingsSort") {
            setFilterData({ ...filterData, [name]: e.target.value })
        } else {
            const selectedDate = new Date(e);
            let date = selectedDate.toLocaleDateString('en-GB');
            setFilterData({ ...filterData, [name]: date });
        }
    }

    useEffect(() => {
        updateStateWithReviewData(reviewData);
    }, [reviewData])

    return (
        <>
            <div className="review-box mt-3">
                <div className="rating-top">
                    <BsFillStarFill /> ({state.averageRating.toFixed(1)}) <span>{reviewData?.length ? reviewData.length : 0} Reviews</span>
                </div>
                <div className="rating-count">
                    <ul>
                        <li>
                            <span>5</span>
                            <div className="rating-percentage">
                                <div className="percentage" style={{ width: `${(+state.fiveCount / (reviewData?.length ? reviewData.length : 0)) * 100}%` }}></div>
                            </div>
                            <span>{state.fiveCount}</span>
                        </li>
                        <li>
                            <span>4</span>
                            <div className="rating-percentage">
                                <div className="percentage" style={{ width: `${(+state.fourCount / (reviewData?.length ? reviewData.length : 0)) * 100}%` }}></div>
                            </div>
                            <span>{state.fourCount}</span>
                        </li>
                        <li>
                            <span>3</span>
                            <div className="rating-percentage">
                                <div className="percentage" style={{ width: `${(+state.threeCount / (reviewData?.length ? reviewData.length : 0)) * 100}%` }}></div>
                            </div>
                            <span>{state.threeCount}</span>
                        </li>
                        <li>
                            <span>2</span>
                            <div className="rating-percentage">
                                <div className="percentage" style={{ width: `${(+state.twoCount / (reviewData?.length ? reviewData.length : 0)) * 100}%` }}></div>
                            </div>
                            <span>{state.twoCount}</span>
                        </li>
                        <li>
                            <span>1</span>
                            <div className="rating-percentage">
                                <div className="percentage" style={{ width: `${(+state.oneCount / (reviewData?.length ? reviewData.length : 0)) * 100}%` }}></div>
                            </div>
                            <span>{state.oneCount}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="review-box mt-3" >
                <div className="widget-elements-title mb-2">
                    <h6>Sort by ratings</h6>
                </div>
                <div className="input-outer">
                    <select
                        className="common-inp alt"
                        name="ratingsSort"
                        value={filterData.ratingsSort}
                        onChange={(e) => handleChange(e, "ratingsSort")}
                    >
                        <option value="">None</option>
                        <option value="ascending">Ascending</option>
                        <option value="descending">Descending</option>
                    </select>
                </div>
            </div>
            <div className="review-box mt-3">
                <div className="widget-elements-title mb-2">
                    <h6>Sort by date</h6>
                    <button className='theme-btn' onClick={handleClearDate}><span>Clear</span></button>
                </div>
                <div className="input-outer">
                    <div className="row align-items-center">
                        <div className="col-6">
                            <div>From</div>
                            <DatePicker
                                className='common-inp'
                                dateFormat='dd/MM/yyyy'
                                placeholder='From'
                                name='fromDate'
                                value={filterData.fromDate}
                                onChange={(e) => handleChange(e, "fromDate")}
                            />
                        </div>
                        <div className="col-6">
                            <div>To</div>
                            <DatePicker
                                name='toDate'
                                placeholder='To'
                                value={filterData.toDate}
                                onChange={(e) => handleChange(e, "toDate")}
                                className='common-inp'
                                dateFormat='dd/MM/yyyy'
                                minDate={new Date(`${filterData.fromDate.split("/")[2]}-${filterData.fromDate.split("/")[1]}-${filterData.fromDate.split("/")[0]}`)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LeftPanel
