import React, { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { BsTrash3, BsPencil, BsFolder, BsEye } from "react-icons/bs";
import ManageLeadModal from './ManageLeadModal';
import SweetAlert from '../../../CommonComponents/SweetAlert';
import ManageLeadRow from './ManageLeadRow';
import { useDispatch } from 'react-redux';
import { onDeleteLeadFolder } from '../../../../Redux/Actions/LeadAction';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ManageLeadCard = ({ curElem, index, mainCheckedArr, setMainCheckedArr, fetchAllFolder, filterData }) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth)
    const leadFolderData = useSelector((state) => state.lead.data)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isCheckedAll, setIsCheckedAll] = useState(false)

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })
    const [currentTodo, setCurrentTodo] = useState([]);

    const onConfirm = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        let data = {
            id: curElem.fId,
            type: "folder"
        }
        dispatch(onDeleteLeadFolder("delete-folder", data, leadFolderData, index, setSweet, mainCheckedArr, setMainCheckedArr))
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const deleteRow = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const handleCheckAll = (e) => {
        if (currentTodo.length > 0) {
            let arr = []
            if (e.target.checked) {
                currentTodo.forEach((curElem) => {
                    arr.push(curElem.id)
                })
                setIsCheckedAll(true)
            } else {
                setIsCheckedAll(false)
                arr = []
            }
            setMainCheckedArr((draft) => {
                draft[index] = arr
            })
        }
    }

    useEffect(() => {
        if (mainCheckedArr.length > 0) {
            if (mainCheckedArr.length === currentTodo.length) {
                setIsCheckedAll(true)
            } else {
                setIsCheckedAll(false)
            }
        } else {
            setIsCheckedAll(false)
        }
    }, [mainCheckedArr, currentTodo])

    useEffect(() => {
        let data = [...curElem.leads];
        const isAnyFilterSelected = Object.values(filterData).some(value => value === true);

        if (isAnyFilterSelected) {
            data = curElem.leads.filter(item => {
                if (filterData.contacted && item.leadStatus === "contacted") {
                    return true;
                }
                if (filterData.noRespond && item.leadStatus === "noRespond") {
                    return true;
                }
                if (filterData.pendingRespond && item.leadStatus === "pendingRespond") {
                    return true;
                }
                if (filterData.dealSecured && item.leadStatus === "dealSecured") {
                    return true;
                }
                if (filterData.claimed && item.mapUrl) {
                    return true;
                }
                if (filterData.unClaimed && !item.mapUrl) {
                    return true;
                }
                return false;
            });
        }
        setCurrentTodo(data);
    }, [filterData, curElem])

    // if (!currentTodo.length > 0 && (filterData.contacted || filterData.noRespond || filterData.pendingRespond || filterData.dealSecured || filterData.claimed || filterData.unClaimed)) {
    //     return;
    // }

    return (
        <>
            <Accordion.Item eventKey={index}>
                <Accordion.Header><BsFolder fontSize={17} /> <span className="ps-3">{curElem.folderName}</span></Accordion.Header>
                <div className="folderLinlk">
                    <Link to={`/lead/find-leads?fId=${curElem.fId}`} style={{ color: "#fff" }}><BsEye fontSize={17} /></Link>
                    {+auth.user.isClientAccount === 1 ? null :
                        <>
                            <span onClick={handleShow}><BsPencil fontSize={17} /></span>
                            <span onClick={deleteRow}><BsTrash3 fontSize={17} /></span>
                        </>
                    }
                </div>
                <Accordion.Body>
                    <div className="table-responsive lead_folder_table">
                        <table className="table widget-table ">
                            <tr>
                                <th width="30">
                                    <label className="table-check">
                                        <input
                                            type="checkbox"
                                            checked={isCheckedAll}
                                            onChange={handleCheckAll}
                                        />
                                        <span className="check-mark"></span>
                                    </label>
                                </th>
                                <th>Business Name</th>
                                <th>Contact Details</th>
                                <th>Status</th>
                                <th>GBP Listing</th>
                                <th>Total Reviews</th>
                                <th className="text-end">Action</th>
                            </tr>

                            {currentTodo.length > 0 ?
                                currentTodo.map((row, rowIndex) => {
                                    return (
                                        <React.Fragment key={rowIndex}>
                                            <ManageLeadRow
                                                curElem={row}
                                                index={index}
                                                mainCheckedArr={mainCheckedArr}
                                                setMainCheckedArr={setMainCheckedArr}
                                                fetchAllFolder={fetchAllFolder}
                                            />
                                        </React.Fragment>
                                    )
                                })
                                : <tr>
                                    <td colSpan={7} className="text-center">
                                        No Leads have been created here yet.
                                    </td>
                                </tr>
                            }
                        </table>
                    </div>
                </Accordion.Body>
            </Accordion.Item>

            <ManageLeadModal
                curElem={curElem}
                type="editFolder"
                show={show}
                handleClose={handleClose}
                fetchData={fetchAllFolder}
            />

            <SweetAlert
                show={sweet.enable}
                message={`Are you sure? <br><br> Do you want to delete this folder?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                alertLoader={sweet.loader}
                handleClose={onCancel}
                performDelete={onConfirm}
            />
        </>
    )
}

export default ManageLeadCard
