import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const TitleBar = (props) => {

    const brandName = useSelector(state => state.rebrand);

    return (
        <>
            <Helmet>
                <title >{brandName.data ? brandName.data.name : 'AIReputors'} | {props.title}</title>
            </Helmet>
        </>
    )
}

export default React.memo(TitleBar)