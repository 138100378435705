import React, { useState } from 'react'
import { onDeleteCampaign } from '../../../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';
import SweetAlert from '../../../CommonComponents/SweetAlert';
import { BsEye, BsPencil, BsTrash3 } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import EmbedPopup from '../../../CommonComponents/EmbedPopup';
import { useSelector } from 'react-redux';

const OptCampRow = (props) => {

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)

    let ebScript = `<script src="https://backend.aireputors.com/assets/optin.js?id=${props.curElem.id}" id="reputor_optin"></script>`

    const [show, setShow] = useState(false);
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onConfirm = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        let data = {
            id: props.curElem.id,
            type: ""
        }
        dispatch(onDeleteCampaign("delete-optin", data, props.campData, props.setCampData, setSweet))
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const deleteRow = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const handleCheck = (e) => {
        let arr = [...props.checkedArr]
        if (e.target.checked) {
            arr.push(props.curElem.id)
        } else {
            let idIndex = props.checkedArr.findIndex((val) => val === props.curElem.id)
            arr.splice(idIndex, 1)
        }
        props.setCheckedArr(arr)

    }

    return (
        <tr>
            <td width="30">
                <label className="table-check">
                    <input
                        type="checkbox"
                        checked={props.checkedArr.includes(props.curElem.id)}
                        onChange={handleCheck}
                    />
                    <span className="check-mark"></span>
                </label>
            </td>
            <td>{props.curElem.name}</td>
            <td>{props.curElem.view}</td>
            <td>{props.curElem.uniqueView}</td>
            <td>{props.curElem.submitClick}</td>
            <td>{props.curElem.created}</td>
            <td className="text-end">
                <span className="widg-icon">
                    {props.curElem.data ?
                        <a onClick={handleShow} title='View'><BsEye /></a> : null}
                    {+auth.user.isClientAccount === 1 ? null :
                        <>
                            <Link to={`/review/optin-style?id=${props.curElem.id}`} title='Edit'><BsPencil /></Link>
                            <a onClick={deleteRow} title='Delete'><BsTrash3 /></a>
                        </>
                    }
                </span>
            </td>

            <EmbedPopup
                script={ebScript}
                handleClose={handleClose}
                show={show}
                type={"Optin"}

            />

            <SweetAlert
                show={sweet.enable}
                message={`Are you sure?<br><br> Do you want to delete this optin widget?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                alertLoader={sweet.loader}
                handleClose={onCancel}
                performDelete={onConfirm}

            />
        </tr>
    )
}

export default OptCampRow