import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { onFetchSettingsData, onSaveSettings } from '../../../../Redux/Actions/ReviewAction';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { GoArrowLeft } from "react-icons/go";
import { useSelector } from 'react-redux';
import TitleBar from '../../../CommonComponents/TitleBar';
import { setAlert } from '../../../../Redux/Actions/AlertActions';

const ReviewSettings = () => {

    const dispatch = useDispatch();
    const location = useLocation()
    const { id, aId, locId, type } = queryString.parse(location.search);
    const auth = useSelector(state => state.auth)

    const [state, setState] = useState({
        autoReply: "",
        phoneNumbers: "",
        contactEmails: "",
        NotificationEmails: "",
        negativeHandler: "",
        positiveHandler: ""

    });
    const [link, setLink] = useState({
        pos: {
            name: "",
            url: ""
        },
        neg: {
            name: "",
            url: ""
        }
    })

    const [loader, setLoader] = useState({
        fetch: true,
        save: false
    })

    const addTags = (event) => {
        const { name, value } = event.target;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(value)) {
            if ((event.key === "," || event.key === "Enter") && value !== "" && state.contactEmails.length < 5 && name === "contactEmails") {
                setState({ ...state, [name]: [...state.contactEmails, value.replace(",", "")] })
                event.target.value = "";
            } else if ((event.key === "," || event.key === "Enter") && value !== "" && state.phoneNumbers.length < 5 && name === "phoneNumbers") {
                setState({ ...state, [name]: [...state.phoneNumbers, value.replace(",", "")] })
                event.target.value = "";
            } else if ((event.key === "," || event.key === "Enter") && value !== "" && state.NotificationEmails.length < 5 && name === "NotificationEmails") {
                setState({ ...state, [name]: [...state.NotificationEmails, value.replace(",", "")] })
                event.target.value = "";
            }
        } else {
            dispatch(setAlert("Please enter valid email", "danger"))
        }
    };

    const handleBlurSingle = (e) => {
        if (state.NotificationEmails.length === 0 && e.target.value) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (emailRegex.test(e.target.value)) {
                setState({ ...state, NotificationEmails: [...state.NotificationEmails, e.target.value] })
                e.target.value = ""
            } else {
                dispatch(setAlert("Please enter valid email", "danger"))
            }
        }
    }

    const removeTags = (id, type) => {
        if (type === "contactEmails") {
            let array = state.contactEmails.filter((_, index) => index !== id);
            setState({ ...state, contactEmails: array })
        } else if (type === "phoneNumbers") {
            let array = state.phoneNumbers.filter((_, index) => index !== id);
            setState({ ...state, phoneNumbers: array })
        } else {
            let array = state.NotificationEmails.filter((_, index) => index !== id);
            setState({ ...state, NotificationEmails: array })
        }
    };

    const handleChange = (e) => {
        const { name, value, checked } = e.target
        if (name === "autoReply") {
            setState({ ...state, [name]: checked })
        } else {
            setState({ ...state, [name]: value })
        }
    }

    const fetchSettingsData = () => {
        let data = {
            id: id,
            accountId: aId,
            locationId: locId
        }
        dispatch(onFetchSettingsData(data, state, setState, loader, setLoader))
    }

    const handleAddLink = (type) => {
        if (type === "pos") {
            if (link.pos.url && link.pos.name) {
                if (link.pos.url.includes("https://") || link.pos.url.includes("http://")) {
                    setState({
                        ...state,
                        positiveHandler: state.positiveHandler + (state.positiveHandler ? `\n${link.pos.name}: ${link.pos.url}` : `${link.pos.name}: ${link.pos.url}`)
                    })
                    setLink({
                        ...link,
                        pos: {
                            ...link.pos,
                            name: "",
                            url: ""
                        }
                    })
                } else {
                    dispatch(setAlert("Please provide valid URL.", "danger"))
                }
            } else if (link.pos.name === "") {
                dispatch(setAlert("Please provide name to create link.", "danger"))
            } else if (link.pos.url === "") {
                dispatch(setAlert("Please provide URL to create link.", "danger"))
            }
        } else {
            if (link.neg.url && link.neg.name) {
                if (link.neg.url.includes("https://") || link.neg.url.includes("http://")) {
                    setState({
                        ...state,
                        negativeHandler: state.negativeHandler + (state.negativeHandler ? `\n${link.neg.name}: ${link.neg.url}` : `${link.neg.name}: ${link.neg.url}`)
                    })
                    setLink({
                        ...link,
                        neg: {
                            ...link.neg,
                            name: "",
                            url: ""
                        }
                    })
                } else {
                    dispatch(setAlert("Please provide valid URL.", "danger"))
                }
            } else if (link.neg.name === "") {
                dispatch(setAlert("Please provide name to create link.", "danger"))
            } else if (link.neg.url === "") {
                dispatch(setAlert("Please provide URL to create link.", "danger"))
            }
        }
    }


    const handleChangeLink = (e, type) => {
        const { name, value } = e.target
        setLink({
            ...link,
            [type]: {
                ...link[type],
                [name]: value
            }
        })
    }


    const handleSave = () => {
        setLoader({ ...loader, save: true })
        let data = {
            integrationId: id,
            locationId: locId,
            accountId: aId,
            autoReply: state.autoReply,
            phoneNumbers: state.phoneNumbers,
            contactEmails: state.contactEmails,
            NotificationEmails: state.NotificationEmails,
            negativeHandler: state.negativeHandler,
            positiveHandler: state.positiveHandler
        }
        dispatch(onSaveSettings(data, loader, setLoader))
    }

    useEffect(() => {
        fetchSettingsData()
    }, [])

    return (
        loader.fetch ?
            <div className="site-wrap">
                <div className="loader-sec">
                    <div className="loader" />
                </div>
            </div>
            :
            <>
                <TitleBar title="Review Inbox" />

                <div className="site-wrap">
                    <div className="site-container">
                        <div className='review_setting_wrap'>
                            <div className="col-12">
                                <div className='mt-3 d-flex align-items-center justify-content-end'>
                                    <Link to={`/review-inbox/review?id=${id}&type=${type}`} className='theme-btn bdr' style={{ width: "130px" }}> <span> <GoArrowLeft />Back</span></Link>
                                    {+auth.user.isClientAccount === 1 ? "" :
                                        <button className='theme-btn ms-1' style={{ width: "130px" }} onClick={handleSave}><span>{loader.save ? <> Saving <i className="fa fa-spinner fa-spin" /></> : "Save"}</span></button>
                                    }
                                </div>
                            </div>
                            <div className="col-12">
                                <div className=''>
                                    <h4 style={{ fontSize: "20px" }}>General settings</h4>
                                    <div style={{ marginBottom: "20px" }}>
                                        <hr style={{ color: '#000', opacity: "1" }} />
                                    </div>
                                </div>

                                <div className="form-check d-flex align-items-center">
                                    <input className="form-check-input"
                                        id="flexCheckDefault"
                                        style={{
                                            width: "25px",
                                            height: "25px",
                                            // background: "transparent",
                                        }}
                                        type="checkbox"
                                        name='autoReply'
                                        checked={state.autoReply}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label ms-3 mt-1" for="flexCheckDefault">
                                        Auto reply to reviews
                                    </label>
                                </div>

                                <div className="input-wrap mt-5">
                                    <label htmlFor="" style={{ fontWeight: "bold" }}>Negative review handler</label>
                                    <textarea
                                        className='common-inp alt'
                                        placeholder='Enter handler here'
                                        name='negativeHandler'
                                        value={state.negativeHandler}
                                        onChange={handleChange}
                                        style={{ minHeight: 200 }}
                                    />
                                    <div className="input-outer mt-2">
                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                            <h6>Create Link </h6>
                                            {/* <FaPlusCircle size={20} color="#4944a7" cursor={"pointer"} title="Add Link" onClick={() => handleAddRemove("add")} /> */}
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                            <div style={{ width: "100%" }} className='me-2'>
                                                <label>Link Name</label>
                                                <input
                                                    className="common-inp alt"
                                                    type="text"
                                                    placeholder="Enter Name"
                                                    name="name"
                                                    value={link.neg.name}
                                                    onChange={(e) => handleChangeLink(e, "neg")}
                                                />
                                            </div>
                                            <div style={{ width: "100%" }} className='me-2'>
                                                <label>Link URL</label>
                                                <input
                                                    className="common-inp alt"
                                                    type="url"
                                                    placeholder="Enter URL"
                                                    name="url"
                                                    value={link.neg.url}
                                                    onChange={(e) => handleChangeLink(e, "neg")}
                                                />
                                            </div>
                                            <div >
                                                <button className="theme-btn mt-4" type="button" onClick={() => handleAddLink("neg")}><span>Add</span></button>
                                                {/* <FaMinusCircle size={20} color="#4944a7" cursor={"pointer"} title="Remove" onClick={() => handleAddRemove("remove", index)} /> */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="input-wrap mt-4">
                                    <label htmlFor="" style={{ fontWeight: "bold" }}>Positive review handler</label>
                                    <textarea
                                        className='common-inp alt'
                                        placeholder='Enter handler here'
                                        name='positiveHandler'
                                        value={state.positiveHandler}
                                        onChange={handleChange}
                                        style={{ minHeight: 200 }}
                                    />
                                    <div className="input-outer mt-2">
                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                            <h6>Create Link </h6>
                                            {/* <FaPlusCircle size={20} color="#4944a7" cursor={"pointer"} title="Add Link" onClick={() => handleAddRemove("add")} /> */}
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                            <div style={{ width: "100%" }} className='me-2'>
                                                <label>Link Name</label>
                                                <input
                                                    className="common-inp alt"
                                                    type="text"
                                                    placeholder="Enter Name"
                                                    name="name"
                                                    value={link.pos.name}
                                                    onChange={(e) => handleChangeLink(e, "pos")}
                                                />
                                            </div>
                                            <div style={{ width: "100%" }} className='me-2'>
                                                <label>Link URL</label>
                                                <input
                                                    className="common-inp alt"
                                                    type="url"
                                                    placeholder="Enter URL"
                                                    name="url"
                                                    value={link.pos.url}
                                                    onChange={(e) => handleChangeLink(e, "pos")}
                                                />
                                            </div>
                                            <div >
                                                <button className="theme-btn mt-4" type="button" onClick={() => handleAddLink("pos")}><span>Add</span></button>
                                                {/* <FaMinusCircle size={20} color="#4944a7" cursor={"pointer"} title="Remove" onClick={() => handleAddRemove("remove", index)} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* <div className="col-12">
                                <div className="review_setting_wrap" style={{ marginTop: "60px" }}>
                                    <div className=''>
                                        <h4 style={{ fontSize: "20px" }}>Contact settings</h4>
                                        <div style={{ marginBottom: "20px" }}>
                                            <hr style={{ color: '#000', opacity: "1" }} />
                                        </div>
                                    </div>

                                    <div className="input-wrap mt-1">
                                        <label htmlFor="">Give emails to handle negative review (Multiple emails with comma separated)</label>
                                        <ul id='EmailTags' className='common-inp d-flex'>
                                            {
                                                state.contactEmails.length > 0 ?
                                                    state.contactEmails.map((curElem, index) => {
                                                        return (
                                                            <li className='email_tag' key={index}>
                                                                <span className="content">{curElem}</span>
                                                                <span className="badge" onClick={() => removeTags(index, "contactEmails")}><i className="fa-solid fa-xmark"></i></span>
                                                            </li>
                                                        )
                                                    })
                                                    : ""
                                            }
                                            <li className='d-flex align-item ' style={{ width: "300px" }}>
                                                <input
                                                    type="email"
                                                    placeholder="Enter email with comma separated"
                                                    name='contactEmails'
                                                    onKeyUp={(event) =>
                                                        event.key === "," || event.key === "Enter" ? addTags(event) : null
                                                    }
                                                    disabled={state.contactEmails.length >= 5 ? true : false}
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="input-wrap mt-3">
                                        <label htmlFor="">Give phone numbers to handle negative review (Multiple phone numbers with comma separated)</label>
                                        <ul id='EmailTags' className='common-inp d-flex'>
                                            {
                                                state.phoneNumbers.length > 0 ?
                                                    state.phoneNumbers.map((curElem, index) => {
                                                        return (
                                                            <li className='email_tag' key={index}>
                                                                <span className="content">{curElem}</span>
                                                                <span className="badge" onClick={() => removeTags(index, "phoneNumbers")}><i className="fa-solid fa-xmark"></i></span>
                                                            </li>
                                                        )
                                                    })
                                                    : ""
                                            }
                                            <li className='d-flex align-item ' style={{ width: "300px" }}>
                                                <input
                                                    type="number"
                                                    placeholder="Enter phone number with comma separated"
                                                    name='phoneNumbers'
                                                    disabled={state.phoneNumbers.length >= 5 ? true : false}
                                                    onKeyUp={(event) =>
                                                        event.key === "," || event.key === "Enter" ? addTags(event) : null
                                                    }
                                                />
                                            </li>
                                        </ul>
                                    </div>

                                </div >
                            </div > */}
                            <div className="col-12">
                                <div className="review_setting_wrap " style={{ marginTop: "60px" }}>
                                    <div className=''>
                                        <h4 style={{ fontSize: "20px" }}>
                                            Notification settings
                                        </h4>
                                        <div style={{ marginBottom: "20px" }}>
                                            <hr style={{ color: '#000', opacity: "1" }} />
                                        </div>
                                    </div >
                                    <div className="input-wrap mt-1">
                                        <label htmlFor="">Give emails to receive review notification (Multiple emails with comma separated)</label>
                                        <ul id='EmailTags' className='common-inp d-flex'>
                                            {
                                                state.NotificationEmails.length > 0 ?
                                                    state.NotificationEmails.map((curElem, index) => {
                                                        return (
                                                            <li className='email_tag' key={index}>
                                                                <span className="content">{curElem}</span>
                                                                <span className="badge" onClick={() => removeTags(index, "NotificationEmails")}><i className="fa-solid fa-xmark"></i></span>
                                                            </li>
                                                        )
                                                    })
                                                    : ""
                                            }
                                            <li className='d-flex align-item' style={{ width: "300px" }}>
                                                <input
                                                    type="email"
                                                    placeholder="Enter email with comma separated"
                                                    name='NotificationEmails'
                                                    disabled={state.NotificationEmails.length >= 5 ? true : false}
                                                    onBlur={(e) => handleBlurSingle(e)}
                                                    onKeyUp={(event) =>
                                                        event.key === "," || event.key === "Enter" ? addTags(event) : null
                                                    }
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </>
    )
}

export default ReviewSettings
