import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { BiSupport, BiLogOut, BiCopy } from "react-icons/bi"
import { MdLockOutline } from "react-icons/md"
import { AiOutlineSetting } from "react-icons/ai"
import { FiUser, FiUserPlus, FiStar } from "react-icons/fi"
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/logo.png"
import { logoutUser } from "../../Redux/Actions/AuthActions";

const Header = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const rebrand = useSelector(state => state.rebrand)
    const auth = useSelector(state => state.auth)
    const [scroll, setScroll] = useState(false);
    const [memberShip, setMemberShip] = useState([])

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);



    const handleLogout = () => {
        dispatch(logoutUser())
    }

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(!show2);

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split('__'))
        }
    }, [auth.user])

    return (
        <>
            <header className={scroll ? "site-header fixed" : "site-header"}>
                <div className="header-in">
                    <div className="site-header-main">
                        <div className="logo">
                            <NavLink className="logo" to="/dashboard">
                                <img alt="" src={rebrand.data ? rebrand.data.logo : logo} />
                            </NavLink>
                        </div>
                        <div className="site-nav" style={{ left: show2 ? 0 : "-300px" }} >

                            <ul>
                                <li><NavLink to="/dashboard">Dashboard</NavLink></li>
                                <li>
                                    <NavLink to="/review">Get Reviews</NavLink>
                                    <ul>
                                        <li><NavLink to="/review/website-widget" className={location.pathname === "/review/website-widget" || location.pathname === "/review" ? "inner-active" : ""}>Website Widget</NavLink></li>
                                        <li><NavLink to="/review/qr-code-generator" className={location.pathname === "/review/qr-code-generator" ? "inner-active" : ""}>QR Code Widget</NavLink></li>
                                        <li><NavLink to="/review/optin-widget" className={location.pathname === "/review/optin-widget" ? "inner-active" : ""} >Optin Widget</NavLink></li>
                                        <li><NavLink to="/review/feedback-widget" className={location.pathname === "/review/feedback-widget" ? "inner-active" : ""}>Feedback Widget </NavLink></li>
                                    </ul>
                                </li>
                                {/* <li><NavLink to="/cold-emails">Cold Emails</NavLink></li> */}
                                <li>
                                    <NavLink to={+auth.user.isClientAccount === 1 ? "/lead/manage-leads" : "/lead"}>Leads</NavLink>
                                    <ul>
                                        {+auth.user.isClientAccount === 1 ? null :
                                            <li><NavLink to="/lead/find-leads" className={location.pathname === "/lead/find-leads" || location.pathname === "/lead" ? "inner-active" : ""}>Find Leads</NavLink></li>
                                        }
                                        <li><NavLink to="/lead/manage-leads" className={location.pathname === "/lead/manage-leads" ? "inner-active" : ""}>Manage Leads</NavLink></li>
                                        {+auth.user.isClientAccount === 1 ? null :
                                            <li><NavLink to="/lead/add-leads" className={location.pathname === "/lead/add-leads" ? "inner-active" : ""}>Add Leads</NavLink></li>
                                        }
                                        <li><NavLink to="/lead/contact-leads" className={location.pathname === "/lead/contact-leads" ? "inner-active" : ""}>Contact Leads</NavLink></li>
                                        <li><NavLink to="/lead/ai-email" className={location.pathname === "/lead/ai-email" ? "inner-active" : ""}>AI Email</NavLink></li>
                                    </ul>
                                </li>

                                <li>
                                    <NavLink to='/post'>Posts</NavLink>
                                    <ul>
                                        {+auth.user.isClientAccount === 1 ? "" :
                                            <>
                                                {/* <li><NavLink to="/post/create?type=Video" className={location.pathname === "/post/create" && location.search === "?type=Video" ? "inner-active" : ""}>Create Videos</NavLink></li> */}
                                                <li><NavLink to="/post/create?type=Post" className={location.pathname === "/post/create" && location.search === "?type=Post" ? "inner-active" : ""}>Create Posts</NavLink></li>
                                                <li><NavLink to="/post/create?type=Image" className={location.pathname === "/post/create" && location.search === "?type=Image" ? "inner-active" : ""}>Create Images</NavLink></li>
                                            </>
                                        }
                                        <li><NavLink to="/post" className={location.pathname === "/post" ? "inner-active" : ""}>Manage Posts</NavLink></li>
                                        <li><NavLink to="/post/schedule" className={location.pathname === "/post/schedule" ? "inner-active" : ""}>Scheduled Posts</NavLink></li>
                                        <li><NavLink to="/post/auto-post" className={location.pathname === "/post/auto-post" || location.pathname === "/post/auto-inner-post" ? "inner-active" : ""}>Auto Posts</NavLink></li>
                                    </ul>
                                </li>

                                <li><NavLink to="/review-inbox">Review Inbox</NavLink></li>
                                {/* <li><NavLink to="/projects">Projects</NavLink></li> */}
                                {+auth.user.isClientAccount === 1 ? "" :
                                    <li><NavLink to="/integration">Integration</NavLink></li>}
                                {/* <li><NavLink to="/help">Help</NavLink></li> */}
                                <li><NavLink to="/help"> Help</NavLink></li>

                            </ul>
                        </div>

                        <div className="profile-link">

                            <div className="menu_bar me-2" onClick={handleClose2}>
                                {show2 ? <i className=" fa-solid fa-xmark" style={{ fontSize: "23px" }}></i> : <i className="fa-solid fa-bars" style={{ fontSize: "18px" }}></i>

                                }

                            </div>
                            <div className="user-nav">
                                <Dropdown>
                                    <Dropdown.Toggle variant="n" id="dropdown-basic">
                                        <div className="user-img">
                                            <div className="user-img-in"><img alt="" src={auth.user.profile} /></div>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <ul>
                                            {+auth.user.isClientAccount === 1 ? "" :
                                                <>
                                                    <li><Link to="/my-account"><span><FiUser /></span> My Account</Link></li>
                                                    <li><Link to="/privacy"><span><MdLockOutline /></span> Privacy</Link></li>

                                                    {+auth.user.isVirtualAccount === 1 ? "" :
                                                        <>
                                                            {memberShip.includes("enterprise") ||
                                                                memberShip.includes("fastpass") ||
                                                                memberShip.includes("bundle") ?
                                                                <>
                                                                    <li><Link to="/account-management"><span><FiUserPlus /></span> Account Management</Link></li>
                                                                    {/* <li><Link to="/settings"><span><AiOutlineSetting /></span> Settings</Link></li> */}
                                                                </>
                                                                : null}
                                                        </>}

                                                    {memberShip.includes("enterprise") ||
                                                        memberShip.includes("platinum") ||
                                                        memberShip.includes("fastpass") ||
                                                        memberShip.includes("bundle") ?
                                                         
                                                        <li><Link to="/upgrade"><span><FiStar /></span> Upgrade</Link></li>
                                                        : null}

                                                </>
                                            }



                                            <li><Link to="/help"><span><BiSupport /></span> Help</Link></li>
                                            <li><Link onClick={handleLogout}><span><BiLogOut /></span> Logout</Link></li>
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>



                    </div>
                </div >
            </header >


        </>
    )
}

export default Header;