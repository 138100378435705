import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import VideoRow from './VideoRow';
import TitleBar from '../../../CommonComponents/TitleBar';
import queryString from 'query-string';
import { GoArrowLeft } from 'react-icons/go';
import { useSelector } from 'react-redux';

const CreateVideo = () => {

    const location = useLocation()
    const { id } = queryString.parse(location.search);
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState({
        fetch: false
    });

    return (
        <>
            <TitleBar title="Review Inbox" />

            <div className="site-wrap">
                <div className="site-container">
                    <section>
                        <div className='d-flex justify-content-between w-100 h-100 align-items-center pt-5 pb-3' >
                            <h5 className="">Your videos</h5>
                            <div className='d-flex'>
                                <Link to={`/review-inbox/review?id=${id}`} className='theme-btn mx-2' style={{ width: "130px" }}> <span> <GoArrowLeft />Back</span></Link>
                                {+auth.user.isClientAccount === 1 ? null :
                                    <Link to={`/review-inbox/create-video?id=${id}`} className='theme-btn'>
                                        <span>Create video</span>
                                    </Link>
                                }
                            </div>

                        </div>

                        <div className="table-area mt-3">
                            <div className="table-responsive">
                                <table className="table widget-table">
                                    <tr>
                                        <th>S.NO</th>
                                        <th>Category</th>
                                        <th>Keyword</th>
                                        <th className="text-end">Action</th>
                                    </tr>

                                    {[].length > 0 ?
                                        [].map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <VideoRow />
                                                </React.Fragment>
                                            )
                                        })
                                        : <tr>
                                            <td colSpan={4} className="text-center">
                                                {loader.fetch ? <i className="fa fa-spin fa-spinner" style={{ color: "#ffffff", fontSize: 25 }} /> : "No video created yet."}
                                            </td>
                                        </tr>
                                    }
                                </table>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default CreateVideo
