import React, { useEffect, useState } from 'react'
import ProofRow from './ProofRow'
import { Link, useLocation } from 'react-router-dom';
import TitleBar from '../../../CommonComponents/TitleBar';
import ProofModal from './ProofModal';
import { useDispatch } from 'react-redux';
import { onFetchProofData } from '../../../../Redux/Actions/ReviewAction';
import queryString from 'query-string';
import { GoArrowLeft } from 'react-icons/go';
import { useSelector } from 'react-redux';

const CreateProof = () => {

    const dispatch = useDispatch();
    const location = useLocation()
    const { id, aId, locId, type } = queryString.parse(location.search);
    const auth = useSelector(state => state.auth)

    const [show, setShow] = useState(false);
    const [proofData, setProofData] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loader, setLoader] = useState({
        fetch: false
    });

    const fetchProof = () => {
        return new Promise((resolve, reject) => {
            setLoader({ ...loader, fetch: true });
            let data = {
                id: id,
                accountId: aId,
                locationId: locId
            }
            dispatch(onFetchProofData(data, setProofData, loader, setLoader, resolve));
        })
    }

    useEffect(() => {
        fetchProof();
    }, []);

    return (
        <>
            <TitleBar title="Review Inbox" />
            <div className="site-wrap">
                <div className="site-container">
                    <section>
                        <div className='d-flex justify-content-between w-100 h-100 align-items-center pt-5 pb-3' >
                            <h5 className="">Your proofs campaigns</h5>
                            <div className='d-flex'>
                                <Link to={`/review-inbox/review?id=${id}&type=${type}`} className='theme-btn mx-2' style={{ width: "130px" }}> <span> <GoArrowLeft />Back</span></Link>
                                {+auth.user.isClientAccount === 1 ? null :
                                    <button className='theme-btn' onClick={handleShow}>
                                        <span>Create proof</span>
                                    </button>
                                }
                            </div>
                        </div>

                        <div className="table-area mt-3">
                            <div className="table-responsive">
                                <table className="table widget-table">
                                    <tr>
                                        <th>S.NO</th>
                                        <th>Name</th>
                                        <th>Loop with Review</th>
                                        <th>Duration</th>
                                        <th className="text-end">Action</th>
                                    </tr>

                                    {proofData.length > 0 ?
                                        proofData.map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <ProofRow
                                                        curElem={curElem}
                                                        index={index}
                                                        proofData={proofData}
                                                        setProofData={setProofData}
                                                        fetchProof={fetchProof}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        : <tr>
                                            <td colSpan={5} className="text-center">
                                                {loader.fetch ? <i className="fa fa-spin fa-spinner spin-style" /> : "No proof created yet."}
                                            </td>
                                        </tr>
                                    }
                                </table>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <ProofModal
                type="create"
                show={show}
                handleClose={handleClose}
                fetchProof={fetchProof}
            />
        </>
    )
}

export default CreateProof
