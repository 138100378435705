import React, { useState, useEffect } from 'react'
import { BsExclamationCircle, BsShop } from 'react-icons/bs'
import { onFetchBusinessCategory, onGenerateAiDescription } from '../../../../Redux/Actions/ReviewAction'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

const BusinessInfo = (props) => {

    const { businessDetails, state, setState, handleUpdateBusiness } = props
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState({
        generate: false
    })
    const [category, setCategory] = useState([])

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({ ...state, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        props.setLoader({ ...props.loader, submit: true })
        handleUpdateBusiness();
    }

    const generateAiDescription = () => {
        setLoader({ ...loader, generate: true })
        let data = {
            id: businessDetails.id
        }
        dispatch(onGenerateAiDescription(data, state, setState, loader, setLoader))
    }
    const fetchCategory = () => {
        let data = {}
        dispatch(onFetchBusinessCategory(data, setCategory, businessDetails.businessCategory?.name))
    }

    useEffect(() => {
        if (businessDetails.businessCategory?.name) {
            fetchCategory();
        }
    }, [businessDetails.businessCategory?.name])

    useEffect(() => {
        if (businessDetails) {
            setState({
                ...state,
                name: businessDetails.name,
                phoneNumber: businessDetails.phoneNumber, //categories/gcid:software_company
                businessCategory: {
                    name: businessDetails.businessCategory?.name,
                    displayName: businessDetails.businessCategory?.displayName
                },
                website: businessDetails.website,
                description: businessDetails.description,
                streetAddress: businessDetails.streetAddress,
                town: businessDetails.town,
                state: businessDetails.state,
                posterCode: businessDetails.posterCode,
                country: businessDetails.country
            })
        }
    }, [businessDetails])

    return (
        <form onSubmit={handleSubmit}>
            <h3><BsShop /> Business Info</h3>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className='input-wrap'>
                        <label htmlFor="">Business Name
                            <span className='tootText'><span className='tootText-icon'><BsExclamationCircle /></span> <span className='tootText-txt'>Add Info Here</span></span>
                        </label>
                        <input
                            className='common-inp alt'
                            type="text"
                            placeholder='Business Name'
                            name='name'
                            value={state.name}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='input-wrap'>
                        <label htmlFor="">Phone Number
                            <span className='tootText'><span className='tootText-icon'><BsExclamationCircle /></span> <span className='tootText-txt'>Add Info Here</span></span>
                            {/* <span className='label-link' onClick={() => handleAddRemove("add", false, "phone")}>+ Additional Number</span> */}
                        </label>
                        <input
                            className='common-inp alt'
                            type="text"
                            placeholder='Phone Number'
                            name='phoneNumber'
                            value={state.phoneNumber}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='input-wrap'>
                        <label htmlFor="">Business Category
                            <span className='tootText'><span className='tootText-icon'><BsExclamationCircle /></span> <span className='tootText-txt'>Add Info Here</span></span>
                            {/* <span className='label-link' onClick={() => handleAddRemove("add", false, "category")}>+ Additional Category</span> */}
                        </label>
                        <select
                            className='common-inp alt commonSelectOpt'
                            name="category"
                            value={state.businessCategory.name + "/" + state.businessCategory.displayName}
                            onChange={handleChange}
                        >
                            <option value="">Select Business Category</option>
                            {category.length > 0 ?
                                category.map((curElem, index) => {
                                    return (
                                        <option key={index} value={"categories/" + curElem.google_category_id + "/" + curElem.category_name}>{curElem.category_name}</option>
                                    )
                                })
                                : ""
                            }
                        </select>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='input-wrap'>
                        <label htmlFor="">Website
                            <span className='tootText'><span className='tootText-icon'><BsExclamationCircle /></span> <span className='tootText-txt'>Add Info Here</span></span>
                        </label>
                        <input
                            className='common-inp alt'
                            type="text"
                            placeholder='Website'
                            name='website'
                            value={state.website}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className='col-lg-6'>
                    <div className='input-wrap'>
                        <label htmlFor="">Business Description
                            <span className='tootText'><span className='tootText-icon'><BsExclamationCircle /></span> <span className='tootText-txt'>Add Info Here</span></span>
                        </label>
                        <div className='txtBtn'>
                            <textarea
                                style={{ height: '197px' }}
                                className='common-inp alt'
                                placeholder='Business Description'
                                name='description'
                                value={state.description}
                                onChange={handleChange}
                            ></textarea>
                            {+auth.user.isClientAccount === 1 ? null :
                                <button className='theme-btn' type='button' onClick={generateAiDescription}>
                                    <span>{loader.generate ? <> Generating description <i className="fa fa-spinner fa-spin" /></> : "Generate description"}</span>
                                </button>
                            }
                        </div>
                    </div>
                </div>

                <div className='col-lg-6'>
                    <div className='input-wrap'>
                        <label htmlFor="">Business Location
                            <span className='tootText'><span className='tootText-icon'><BsExclamationCircle /></span> <span className='tootText-txt'>Add Info Here</span></span>
                            {/* <span className='label-link'><BsPinMapFill /> Map Position</span> */}
                        </label>
                        <input
                            className='common-inp alt'
                            type="text"
                            placeholder='Street Address'
                            name='streetAddress'
                            value={state.streetAddress}
                            onChange={handleChange}
                        />
                        <input
                            className='common-inp alt mt-3'
                            type="text"
                            placeholder='Town'
                            name='town'
                            value={state.town}
                            onChange={handleChange}
                        />
                        <input
                            className='common-inp alt mt-3'
                            type="text"
                            placeholder='State'
                            name='state'
                            value={state.state}
                            onChange={handleChange}
                        />
                        <input
                            className='common-inp alt mt-3'
                            type="text"
                            placeholder='Poster Code'
                            name='posterCode'
                            value={state.posterCode}
                            onChange={handleChange}
                        />
                        <input
                            className='common-inp alt mt-3'
                            type="text"
                            placeholder='Country'
                            name='country'
                            value={state.country}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>

            <hr className='mt-5' />

            <div className='input-wrap'>
                {+auth.user.isClientAccount === 1 ? null :
                    <button type="submit" className='theme-btn'><span>{props.loader.submit ? <> Updating <i className="fa fa-spinner fa-spin" /></> : "Update"}</span></button>
                }
            </div>
        </form>
    )
}

export default BusinessInfo