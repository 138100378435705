import produce from "immer"
const initialState = {
    data: false,
    loader: false
}

export const LeadReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_LEAD_FOLDER_DATA":
            return produce(state, (draft) => {
                draft.data = action.payload.data
                draft.loader = action.payload.loader
            })
        case "DELETE_FOLDER_LEAD":
            return produce(state, (draft) => {
                draft.data[action.payload.index].leads = action.payload.data
            })

        case "CHANGE_FOLDER_LEAD_STATUS":
            return produce(state, (draft) => {
                draft.data[action.payload.index].leads[action.payload.leadIndex].leadStatus = action.payload.status
            })

        case "MOVE_FOLDER_LEAD":
            return produce(state, (draft) => {
                const moveFolderIndex = draft.data.findIndex((val) => +val.fId === +action.payload.data.fId)
                const updatedArray = draft.data[action.payload.index].leads.filter((val) => +val.id !== +action.payload.data.id[0])
                const currentLead = draft.data[action.payload.index].leads.find((val) => +val.id === +action.payload.data.id[0])
                draft.data[action.payload.index].leads = updatedArray
                draft.data[moveFolderIndex].leads.push(currentLead)
            })

        case "CHANGE_FOLDER_NAME":
            return produce(state, (draft) => {
                const folderIndex = draft.data.findIndex((val) => +val.fId === +action.payload.data.id)
                draft.data[folderIndex].folderName = action.payload.data.name
            })

        case "UNMOUNT_LEAD_FOLDER_DATA":
            return produce(state, (draft) => {
                draft.data = false
                draft.loader = false
            })

        default:
            return state
    }
}