import React, { useState } from 'react'
import { onSubmitFacebookDefault } from '../../../Redux/Actions/SocialActions'
import { useDispatch } from 'react-redux'
import { Modal } from 'react-bootstrap'

const FacebookModalConnect = (props) => {
    const dispatch = useDispatch()
    const [data, setData] = useState({
        appId: "",
        secretKey: ""
    })
    const [loader, setLoader] = useState(false)


    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(onSubmitFacebookDefault(data, setLoader, props.handleClose))
    }

    return (
        <Modal className="theme-modal" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <form onSubmit={handleSubmit}>

                    <div className="connect-modal">
                        <h2 className='text-capitalize'><span>Connect Facebook</span> </h2>

                        <div className="input-wrap">
                            <label htmlFor="">Enter App Id</label>
                            <div className="input-outer">
                                <input
                                    className="common-inp"
                                    type="text"
                                    placeholder="Enter App Id"
                                    name='appId'
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="input-wrap">
                            <label htmlFor="">Enter Secret Key</label>
                            <div className="input-outer">
                                <input
                                    className="common-inp"
                                    type="text"
                                    placeholder="Enter Secret Key"
                                    name='secretKey'
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className="group-btn">
                        <div className="row">
                            <div className="col-6">
                                <button className="theme-btn full bdr" style={{ cursor: "pointer" }} type='button' onClick={props.handleClose}><span>Cancel</span></button>
                            </div>
                            <div className="col-6">
                                <button className="theme-btn full" type='submit' style={{ cursor: "pointer" }}><span>{loader ? <>Connecting <i className='fa fa-spin fa-spinner' /></> : "Connect"}</span></button>
                            </div>
                        </div>
                    </div>
                </form>

            </Modal.Body>
        </Modal>
    )
}

export default FacebookModalConnect