import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { BsClockHistory, BsEye, BsPencil, BsTrash3 } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import SweetAlert from '../../../../CommonComponents/SweetAlert';
import { onDeleteCampaign } from '../../../../../Redux/Actions/CommonActions';
import { Link } from 'react-router-dom';

const PostRow = ({ curElem, post, setPost, handleShow }) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onConfirm = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        let data = {
            id: curElem.id
        }
        dispatch(onDeleteCampaign("delete-post", data, post, setPost, setSweet))
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const deleteRow = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    return (
        <>
            <div className='post-list-single'>
                <div className='post-info'>
                    <div className='post-info-img'><img src={curElem.url[0] ? curElem.url[0] : "https://images.pexels.com/photos/20393167/pexels-photo-20393167/free-photo-of-lake-in-town.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"} alt="" /></div>
                    <div className='post-info-txt'>
                        <h5>{curElem.title}</h5>
                        <h6>Jan 03 12:00 AM - Jan 04 12:00 AM</h6>
                        {/* <p>Get 50% off this week</p> */}
                    </div>
                </div>
                <div className='post-data'>
                    <div><BsClockHistory /> {curElem.created.split(" ")[0]}</div>
                    <div className='post-data-action'>
                        {+auth.user.isClientAccount === 1 ? null :
                            <>
                                {curElem.path ?
                                    <Link to={curElem.path} target='_blank' style={{ color: "#000" }}><BsEye /></Link>
                                    : ""
                                }
                                <span><BsPencil onClick={() => handleShow(curElem.id, "edit")} /></span>
                                <span><BsTrash3 onClick={deleteRow} /></span>
                            </>
                        }
                    </div>
                </div>
            </div>

            <SweetAlert
                show={sweet.enable}
                message={`Are you sure? <br><br> Do you want to delete this campaign?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                alertLoader={sweet.loader}
                handleClose={onCancel}
                performDelete={onConfirm}
            />

        </>
    )
}

export default PostRow
