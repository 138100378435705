import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai';

const TrainingPopup = ({show, handleClose, auth, colorObj}) => {

    const [showClose, setShowClose] = useState(false)

    const handelPopupClick = () => {
        document.cookie = `trainingPop${auth.user.id}=true;`;
       handleClose()
    }

    const handleMsgClick = () => {
        document.cookie = `trainingPop${auth.user.id}=true;`;
        handleClose()
    }

    return (
        <Modal className="theme-modal ai_img_wrap video-modal ds" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={() => setShowClose(true)} className="vidClose" >
                    <div style={{ position: "relative" }}>
                        <div className='modalClose' ><AiOutlineClose style={{
                            fontSize: "30px", marginBottom: "3px", top: '-3px',
                            right: '-10px', position: "absolute"
                        }} /></div>
                        {showClose ?
                            <p style={colorObj} onClick={handleMsgClick}>Don't show me again</p> : ""}

                    </div>

                </div>
                <div className="modalVid">
                    {/* <a href="https://aIclubhub.com/" target="_blank" rel="nooper noreferrer" onClick={handelPopupClick}>
                                            <img src={BannerImg} alt="bannerImg" title="AiStaff-Fb-Group" />
                                        </a> */}
                    <div className="para_ai h-100">
                        <a href="https://abhineos.com/training/" target="_blank" rel="nooper noreferrer" onClick={handelPopupClick} style={{ display: "block" }} className="w-100 d-flex h-100 justify-content-center align-items-center flex-column">
                            <p style={{
                                fontSize: "55px",
                                fontWeight: "bold",
                                textAlign: "center",
                                lineHeight: "1.3",
                                color: "#fff"
                            }}>Join AIReputors Customers Only LIVE Training Session: 4th April @ 1pm ET/NY </p>
                            <button className="btn btn-danger mt-3 ps-4 pe-4 pt-2 pb-2" style={{ display: " block", fontSize: "45px" }}> SIGNUP NOW</button>
                        </a>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default TrainingPopup