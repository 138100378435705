import React, { useEffect, useState } from 'react'
import { BsFillStarFill, BsReplyAll, BsStar, BsStarHalf, BsTrash3 } from 'react-icons/bs'
import SweetAlert from '../../../CommonComponents/SweetAlert'
import { useDispatch } from 'react-redux'
import { onGenerateReply, onSendReply } from '../../../../Redux/Actions/ReviewAction'
import { useSelector } from 'react-redux'
import { setAlert } from '../../../../Redux/Actions/AlertActions'

const ReviewCard = (props) => {

    const { curElem, index, reviewData, setReviewData, integrationId, type } = props
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth)
    const socialYoutube = useSelector((state) => state.social.google)
    const socialFb = useSelector((state) => state.social.facebook)
    const socialZillow = useSelector((state) => state.social.zillow)
    const [gmbProfile, setGmbProfile] = useState({
        name: "",
        profile: ""
    })
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(!show)

    const [reply, setReply] = useState("")
    const [loader, setLoader] = useState({
        generate: false,
        send: false,
    });

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const handleSendReply = () => {
        if (reply) {
            setLoader({ ...loader, send: true })
            let data = {
                id: integrationId,
                reviewId: curElem.reviewId,
                locationId: curElem.locationId,
                accountId: type === "facebook" ? curElem.data?.name : curElem.data?.name?.split("/")[1],
                message: reply
            }
            setReply("")
            dispatch(onSendReply(data, reviewData, setReviewData, loader, setLoader))
        } else {
            dispatch(setAlert("Please enter reply message", "danger"))
        }

    }

    const handleGenerateReply = () => {
        if (type === "facebook") {
            let currentGoogleAccount = socialFb.find((val) => +val.id === +integrationId)
            if (currentGoogleAccount) {
                setLoader({ ...loader, generate: true })
                let data = {
                    name: currentGoogleAccount.username,
                    comment: curElem.data?.comment ? curElem.data?.comment : "",
                    sentiment: curElem.reviewSentiment,
                }
                dispatch(onGenerateReply(data, setReply, loader, setLoader))
            }
        } else if(type === "google"){
            let currentGoogleAccount = socialYoutube.find((val) => +val.id === +integrationId)
            if (currentGoogleAccount) {
                setLoader({ ...loader, generate: true })
                let data = {
                    name: currentGoogleAccount.username,
                    comment: curElem.data?.comment ? curElem.data?.comment : "",
                    sentiment: curElem.reviewSentiment,
                }
                dispatch(onGenerateReply(data, setReply, loader, setLoader))
            }
        }else{
            let currentGoogleAccount = socialZillow.find((val) => +val.id === +integrationId)
            if (currentGoogleAccount) {
                setLoader({ ...loader, generate: true })
                let data = {
                    name: currentGoogleAccount.username,
                    comment: curElem.data?.comment ? curElem.data?.comment : "",
                    sentiment: curElem.reviewSentiment,
                }
                dispatch(onGenerateReply(data, setReply, loader, setLoader))
            }
        }

    }

    const onConfirm = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        let data = {
            id: curElem.id,
            type: "folder"
        }
        // dispatch(onDeleteLeadFolder("delete-folder", data, leadFolderData, index, setSweet, mainCheckedArr, setMainCheckedArr))
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const convertNumber = (strNum) => {
        switch (strNum?.toLowerCase()) {
            case "zero":
                return 0;
            case "one":
                return 1;
            case "two":
                return 2;
            case "three":
                return 3;
            case "four":
                return 4;
            case "five":
                return 5;
            default:
                return 0; // Not a number
        }
    }

    const deleteRow = () => {
        setSweet({
            ...sweet,
            enable: true
        })
    }

    useEffect(() => {
        setReply("");
        setShow(false);
        if (type === "facebook") {
            if (socialFb.length > 0 && integrationId) {
                let currentAccount = socialFb.find((val) => +val.id === +integrationId)
                setGmbProfile({
                    name: currentAccount.username,
                    profile: currentAccount.image
                })
            }
        } else {
            if (socialYoutube.length > 0 && integrationId) {
                let currentAccount = socialYoutube.find((val) => +val.id === +integrationId)
                setGmbProfile({
                    name: currentAccount.username,
                    profile: currentAccount.image
                })
            }
        }


    }, [integrationId])


    return (
        <div className="feedback" style={{ flexDirection: 'column' }}>

            <div className="d-flex">
                <div className="feedback-user">
                    <div className="feedback-user-img"><img src={curElem.data.reviewer.profilePhotoUrl} alt="" /></div>
                </div>
                <div className="feedback-txt" style={{ position: "relative" }}>
                    <div className='d-flex justify-content-between align-items-end w-100' >
                        <div>
                            <h6 className='py-1'>{curElem.data.reviewer.displayName}</h6>
                            <div className="rating-top py-1">
                                <div className="star-group">
                                    {[...Array(5)].map((_, index) => (
                                        index < convertNumber(curElem.data.starRating) ? (
                                            <BsFillStarFill key={index} />
                                        ) : (
                                            <BsStar key={index} />
                                        )
                                    ))}
                                </div>
                                ({convertNumber(curElem.data.starRating)})
                            </div>
                            <p className='py-1'>{curElem.data.comment}</p>
                            <p className='py-1 text-white review_date'>{curElem.data.createTime?.split("T")[0]}</p>
                            {curElem.data?.reviewReply?.comment ?
                                <div className="review_wrap_reply mt-3">
                                    <div style={{ width: "60px" }}>
                                        <div className="review_img_user" style={{ width: "50px", height: "50px", }}>
                                            <img src={gmbProfile.profile} alt="" style={{ borderRadius: "100%" }} />
                                        </div>

                                    </div>

                                    <div style={{ width: "calc(100% - 60px)", paddingLeft: "2px", marginTop: "8px" }}>
                                        <p className='text-white review_reply' style={{ fontWeight: "bold" }}>{gmbProfile.name}</p>
                                        <p className=' text-white review_reply' >{curElem.data?.reviewReply?.comment}</p>
                                        <p className=' text-white review_date' >{curElem.data?.reviewReply?.updateTime?.split("T")[0]}</p>
                                    </div>
                                </div>
                                : ""
                            }
                        </div>
                    </div>
                </div>
            </div>


            <div className="auto_reply_wraps" style={{ position: "relative" }}>
                <div className="col-12">
                    <div className="rating-top-outer justify-content-end">
                        <div className="revIcon">
                            {+auth.user.isClientAccount === 1 ? null :
                                <>
                                    <span><BsReplyAll onClick={handleShow} /></span>
                                    {/* <span title='This has been disabled by the administrator' style={{ color: "gray" }} ><BsTrash3 /></span> */}
                                </>
                            }
                        </div>
                    </div>
                    {show && (
                        <div className="auto_reply row mt-3">
                            <div className="col-12">
                                <div className="row justify-content-xxl-end justify-content-md-center justify-content-start" >
                                    <div className="col-8">
                                        <div className="auto_input">
                                            <textarea id="" cols="30" rows="10" className="common-inp alt pt-2" name="reply" value={reply} style={{ border: "0" }} onChange={(e) => setReply(e.target.value)} ></textarea>
                                            <div className="auto_reply_btn">
                                                <button className="theme-btn" onClick={handleGenerateReply} disabled={loader.generate}><span>{loader.generate ? <> Generating reply <i className="fa fa-spinner fa-spin ms-1" /></> : "Generate reply"}</span></button>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-2">
                                        <div className="auto_send">
                                            <button className="theme-btn" onClick={handleSendReply} disabled={loader.generate}><span>{loader.send ? <> Sending <i className="fa fa-spinner fa-spin ms-1" /></> : "Send"}</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    }
                </div>
            </div>
            <div className="feedback_ribbon" style={{ backgroundColor: curElem.reviewSentiment.toLowerCase() === "positive" ? "green" : curElem.reviewSentiment.toLowerCase() === "negative" ? "red" : "#FFC107" }}>
                <span>{curElem.reviewSentiment}</span>
            </div>
            <SweetAlert
                show={sweet.enable}
                message={`Are you sure? <br> <br> Do you want to delete this review?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                alertLoader={sweet.loader}
                handleClose={onCancel}
                performDelete={onConfirm}
            />
        </div >

    )
}

export default ReviewCard
