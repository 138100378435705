import React, { useEffect, useState } from 'react';
import TitleBar from '../../../CommonComponents/TitleBar'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { BsShop, BsListUl, BsFileEarmarkText, BsImages, BsBell } from "react-icons/bs";
import BusinessInfo from './BusinessInfo';
import ServicesInfo from './ServicesInfo';
import { onFetchBusinessDetails, onUpdateBusinessInfo } from '../../../../Redux/Actions/ReviewAction';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { GoArrowLeft } from 'react-icons/go';
import Post from './Post/Post';
import PostImages from './Post/PostImages';
import { useImmer } from 'use-immer';

const UpdateProfile = () => {

    const dispatch = useDispatch();
    const location = useLocation()
    const { id, aId, locId, type } = queryString.parse(location.search);
    const [loader, setLoader] = useState({
        submit: false,
        fetch: true
    })
    const [businessDetails, setBusinessDetails] = useState({});
    const [businessPhotos, setBusinessPhotos] = useState([]);

    const [state, setState] = useImmer({
        name: "",
        businessCategory: {
            name: "",
            displayName: ""
        },
        phoneNumber: "",
        website: "",
        description: "",
        streetAddress: "",
        town: "",
        state: "",
        posterCode: "",
        country: ""
    })

    const [servicesInfo, setServiceInfo] = useImmer({
        town: businessDetails.town,
        serviceTypesId: "",
        openingHours: [
            {
                day: "Sunday",
                enable: false,
                fromTime: "9:00",
                toTime: "9:00",
            },
            {
                day: "Monday",
                enable: false,
                fromTime: "9:00",
                toTime: "9:00",
            },
            {
                day: "Tuesday",
                enable: false,
                fromTime: "9:00",
                toTime: "9:00",
            },
            {
                day: "Wednesday",
                enable: false,
                fromTime: "9:00",
                toTime: "9:00",
            },
            {
                day: "Thursday",
                enable: false,
                fromTime: "9:00",
                toTime: "9:00",
            },
            {
                day: "Friday",
                enable: false,
                fromTime: "9:00",
                toTime: "9:00",
            },
            {
                day: "Saturday",
                enable: false,
                fromTime: "9:00",
                toTime: "9:00",
            }
        ]
    })

    const handleUpdateBusiness = () => {
        let regularHours = []
        if (servicesInfo.openingHours.length > 0) {
            regularHours = servicesInfo.openingHours.map((curElem) => {
                if (curElem.enable) {
                    return (
                        {
                            openDay: curElem.day,
                            openTime: {
                                hours: curElem.fromTime.split(":")[0],
                                minutes: curElem.fromTime.split(":")[1]
                            },
                            closeDay: curElem.day,
                            closeTime: {
                                hours: curElem.toTime.split(":")[0],
                                minutes: curElem.toTime.split(":")[1]
                            }
                        }
                    )
                }
            })
        }

        let data = {
            id: id,
            locationId: locId,
            data: {
                title: state.name,
                categories: {
                    primaryCategory: {
                        name: state.businessCategory.name,
                        displayName: state.businessCategory.displayName,
                        serviceTypes: businessDetails.businessCategory?.serviceTypes,
                        // selectedServiceTypes: servicesInfo.serviceTypesId
                    }
                },
                storefrontAddress: {
                    regionCode: state.country,
                    postalCode: state.posterCode,
                    administrativeArea: state.state,
                    locality: state.town,
                    addressLines: state.streetAddress
                },
                websiteUri: state.website,
                regularHours: {
                    periods: regularHours
                },
                profile: {
                    description: state.description
                }
            }
        };
        dispatch(onUpdateBusinessInfo(data, loader, setLoader))
    }

    const fetchBusinessDetails = () => {
        let data = {
            id: id,
            accountId: aId,
            locationId: locId
        };
        dispatch(onFetchBusinessDetails(data, setBusinessDetails, setBusinessPhotos, loader, setLoader))
    }

    useEffect(() => {
        fetchBusinessDetails()
    }, []);


    return (
        loader.fetch ?
            <div className="site-wrap">
                <div className="loader-sec">
                    <div className="loader" />
                </div>
            </div>
            :
            <>
                <TitleBar title="Review Inbox" />

                <div className="site-wrap">
                    <div className="site-container">
                        <div className='input-wrap' style={{ textAlign: "right" }}>
                            <Link to={`/review-inbox/review?id=${id}&type=${type}`} className='theme-btn mx-2' style={{ width: "130px" }}> <span> <GoArrowLeft />Back</span></Link>
                        </div>
                        {type === "zillow" ?
                            <div className='text-center mt-3'>This account does not support this feature.</div>
                            :

                            <div>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <div className='profile-tabs-wrap' style={{ display: "block" }}>
                                        <div className="row">
                                            <div className="col-lg-3 mb-lg-0 mb-5">
                                                <div className='profile-tabs-left account-nav'>
                                                    <Nav variant="dd" className="flex-column">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="first"><BsShop /> Business Info</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="second"><BsListUl /> Services Info</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="third"><BsFileEarmarkText /> Posts</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="fourth"><BsImages /> Photos</Nav.Link>
                                                        </Nav.Item>
                                                        {/*<Nav.Item>*/}
                                                        {/*    <Nav.Link eventKey="fifth"><BsBell /> Notifications</Nav.Link>*/}
                                                        {/*</Nav.Item>*/}
                                                    </Nav>
                                                </div>
                                            </div>
                                            <div className="col-lg-9">
                                                <div className='profile-tabs-right' style={{ width: "100%" }}>
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="first">
                                                            <BusinessInfo
                                                                businessDetails={businessDetails}
                                                                state={state}
                                                                setState={setState}
                                                                loader={loader}
                                                                setLoader={setLoader}
                                                                handleUpdateBusiness={handleUpdateBusiness}
                                                            />
                                                        </Tab.Pane>

                                                        <Tab.Pane eventKey="second">
                                                            <ServicesInfo
                                                                businessDetails={businessDetails}
                                                                servicesInfo={servicesInfo}
                                                                setServiceInfo={setServiceInfo}
                                                                loader={loader}
                                                                setLoader={setLoader}
                                                                handleUpdateBusiness={handleUpdateBusiness} />
                                                        </Tab.Pane>

                                                        <Tab.Pane eventKey="third">
                                                            <Post businessDetails={businessDetails} />
                                                        </Tab.Pane>

                                                        <Tab.Pane eventKey="fourth">
                                                            <div>
                                                                <h3><BsImages /> Photos</h3>
                                                                <PostImages
                                                                    photos={businessPhotos}
                                                                    setPhotos={setBusinessPhotos}
                                                                    type={"photos"}
                                                                />
                                                            </div>
                                                        </Tab.Pane>

                                                        <Tab.Pane eventKey="fifth">
                                                            <div>
                                                                <h3><BsBell /> Notifications</h3>
                                                            </div>
                                                        </Tab.Pane>

                                                    </Tab.Content>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Container>
                            </div>}
                    </div>
                </div>
            </>
    )
}

export default UpdateProfile
