import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { BsXLg } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { onCreateProof, onUpdateProof } from '../../../../Redux/Actions/ReviewAction'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

const ProofModal = ({ curElem, show, handleClose, type, fetchProof }) => {

    const dispatch = useDispatch();
    const location = useLocation()
    const user = useSelector((state) => state.auth.user);
    const { id, aId, locId } = queryString.parse(location.search);

    const [state, setState] = useState({
        id: "",
        integrationId: "",
        locationId: "",
        accountId: "",
        name: "",
        duration: 7,
        loop: false
    })
    const [embedCode, setEmbedCode] = useState("");
    const [copyButtonText, setCopyButtonText] = useState("Copy")

    const [loader, setLoader] = useState({
        submit: false
    })

    const handleChange = (e) => {
        const { name, value, checked } = e.target
        if (name === "loop") {
            setState({ ...state, [name]: checked })
        } else {
            setState({ ...state, [name]: value })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (type === "create") {
            setLoader({ ...loader, submit: true })
            if (curElem) {
                let data = {
                    id: state.id,
                    integrationId: state.integrationId,
                    accountId: state.accountId,
                    locationId: state.locationId,
                    name: state.name,
                    loop: state.loop,
                    duration: state.duration
                }
                dispatch(onUpdateProof(data, setState, loader, setLoader, fetchProof, handleClose));
            } else {
                let data = {
                    integrationId: id,
                    accountId: aId,
                    locationId: locId,
                    name: state.name,
                    loop: state.loop,
                    duration: state.duration
                }
                dispatch(onCreateProof(data, setState, loader, setLoader, fetchProof, handleClose));
            }
        } else {
            setCopyButtonText("Copied")
            navigator.clipboard.writeText(embedCode)
        }
    }

    useEffect(() => {
        if (curElem) {
            setState({
                ...state,
                id: curElem.id,
                integrationId: curElem.integrationId,
                accountId: curElem.accountId,
                locationId: curElem.locationId,
                name: curElem.name,
                loop: +curElem.loop === 1 ? true : false,
                duration: curElem.duration
            })
            setCopyButtonText("Copy")
            setEmbedCode(`<script type="text/javascript" id="google_review_widget" src='https://backend.aireputors.com/assets/review-embed/index.js?id=${curElem.userId}&intgId=${curElem.integrationId}&aId=${curElem.accountId}&locId=${curElem.locationId}&lo=${+curElem.loop === 1 ? true : false}&du=${curElem.duration}'></script>`)
        }
    }, [curElem])

    useEffect(() => {
        if (copyButtonText === "Copied") {
            setCopyButtonText("Copy")
        }
    }, [show])

    return (
        <Modal className="theme-modal" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className="modalClose" onClick={handleClose}><BsXLg /></div>
                <form action="" onSubmit={handleSubmit}>
                    {type === "view" ?
                        <>
                            <h5>Embed code</h5>
                            <div className="input-outer mt-4">
                                <textarea
                                    className="common-inp alt"
                                    cols="10"
                                    rows="4"
                                    value={embedCode}
                                    readOnly
                                />
                            </div>
                        </>
                        :
                        <div className='proof_modal'>
                            <h5>Create proof</h5>
                            <div className="input-outer mt-4">
                                <label className="pb-3" htmlFor="">Name</label>
                                <input
                                    className="common-inp alt"
                                    type="text"
                                    placeholder="Enter proof name"
                                    name='name'
                                    value={state.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="input-outer mt-4">
                                <label className="pb-3" htmlFor="">Review duration in seconds</label>
                                <input
                                    className="common-inp alt"
                                    type="number"
                                    placeholder="Enter review duration"
                                    name='duration'
                                    value={state.duration}
                                    onChange={handleChange}
                                />
                            </div>

                            <div class="form-check d-flex align-items-center mt-3">
                                <input class="form-check-input"
                                    id="flexCheckDefault"
                                    style={{
                                        width: "25px",
                                        height: "25px",
                                    }}
                                    type="checkbox"
                                    name='loop'
                                    checked={state.loop}
                                    onChange={handleChange}
                                />
                                <label class="form-check-label ms-3 mt-1" for="flexCheckDefault">
                                    Loop with reviews
                                </label>
                            </div>
                        </div>
                    }
                    <div className="input-outer mt-4">
                        <div className="row">
                            <div className="col-6">
                                <button type='button' className="theme-btn bdr full" onClick={handleClose}><span>Cancel</span></button>
                            </div>
                            <div className="col-6">
                                <button type="submit" className="theme-btn full">
                                    {type === "create" ?
                                        curElem ?
                                            <span>{loader.submit ? <> Updating <i className="fa fa-spinner fa-spin ms-1" /></> : "Update"}</span>
                                            :
                                            <span>{loader.submit ? <> Creating <i className="fa fa-spinner fa-spin ms-1" /></> : "Create"}</span>
                                        :
                                        <span>{copyButtonText}</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ProofModal
