import React, { useEffect, useState } from 'react';
import { BsListUl, BsExclamationCircle } from "react-icons/bs";
import OpeningHours from './OpeningHours';
import { useSelector } from 'react-redux';


const ServicesInfo = (props) => {

    const auth = useSelector(state => state.auth)
    const { businessDetails, servicesInfo, setServiceInfo, handleUpdateBusiness } = props
    const [serviceTypes, setServiceTypes] = useState([]);

    const handleChange = (e) => {
        const { name, value } = e.target
        setServiceInfo({ ...servicesInfo, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        props.setLoader({ ...props.loader, submit: true })
        handleUpdateBusiness();
    }

    useEffect(() => {
        if (businessDetails && businessDetails?.regularHours?.length > 0) {
            const mergedOpeningHours = servicesInfo.openingHours.map(defaultHour => {
                const regularHour = businessDetails.regularHours.find(regularHour => regularHour.openDay.toUpperCase() === defaultHour.day.toUpperCase());
                if (regularHour) {
                    return {
                        day: regularHour.openDay,
                        enable: true,
                        fromTime: `${regularHour.openTime.hours}:${regularHour.openTime.minutes}`,
                        toTime: `${regularHour.closeTime.hours}:${regularHour.closeTime.minutes}`,
                    };
                } else {
                    return defaultHour;
                }
            });
            setServiceInfo({
                ...servicesInfo,
                town: businessDetails.town,
                openingHours: mergedOpeningHours
            });
            setServiceTypes(businessDetails.businessCategory?.serviceTypes ? businessDetails.businessCategory.serviceTypes : [])
        }
    }, [businessDetails])

    return (
        <div>
            <h3><BsListUl /> Services Info</h3>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className='input-wrap'>
                        <label htmlFor="">Service Offered
                            <span className='tootText'><span className='tootText-icon'><BsExclamationCircle /></span> <span className='tootText-txt'>Add Info Here</span></span>
                        </label>
                        <select
                            className='common-inp alt commonSelectOpt'
                            name="serviceTypesId"
                            value={servicesInfo.serviceTypesId}
                            onChange={handleChange}
                        >
                            <option value="">Select service</option>
                            {serviceTypes.length > 0 ?
                                serviceTypes.map((curElem, index) => {
                                    return (
                                        <option key={index} value={curElem.serviceTypeId}>{curElem.displayName}</option>
                                    )
                                })
                                : ""
                            }
                        </select>
                    </div>
                    <div className='input-wrap'>
                        <label htmlFor="">Service Area
                            <span className='tootText'><span className='tootText-icon'><BsExclamationCircle /></span> <span className='tootText-txt'>Add Info Here</span></span>
                            {/* <span className='label-link'>+ Add Area</span> */}
                        </label>
                        <input
                            className='common-inp alt'
                            type="text"
                            placeholder='Enter a Location'
                            name='town'
                            value={servicesInfo.town}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='openTime'>
                        {servicesInfo.openingHours?.length > 0 ?
                            servicesInfo.openingHours.map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <OpeningHours
                                            curElem={curElem}
                                            index={index}
                                            setState={setServiceInfo}
                                        />
                                    </React.Fragment>
                                )
                            })
                            : ""
                        }
                    </div>
                </div>
            </div>

            <hr />

            <div className='input-wrap'>
                {+auth.user.isClientAccount === 1 ? null :
                    <button type="button" className='theme-btn' onClick={handleSubmit}><span>{props.loader.submit ? <> Updating <i className="fa fa-spinner fa-spin" /></> : "Update"}</span></button>
                }
            </div>

        </div>
    )
}

export default ServicesInfo
