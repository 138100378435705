import React, { useEffect, useState } from 'react'
import { BsFileEarmarkText, BsExclamationCircle } from "react-icons/bs";
import { GoArrowLeft } from 'react-icons/go';
import PostImages from './PostImages';
import { useImmer } from 'use-immer';
import PostMobileView from './PostMobileView';
import { onCreateBusinessPost, onFetchPostDetails } from '../../../../../Redux/Actions/ReviewAction';
import { useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CreatePost = ({ handleClose, fetchPosts, type }) => {

    let date = new Date()
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch();
    const location = useLocation()
    const { id, aId, locId } = queryString.parse(location.search)
    const [photos, setPhotos] = useState([]);
    const [state, setState] = useImmer({
        title: "",
        url: [],
        fromDate: "",
        toDate: "",
        fromTime: "",
        toTime: ""
    })

    const [loader, setLoader] = useState({
        fetch: false,
        submit: false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({ ...state, [name]: value })
    }

    const handleChangeDate = (e, name) => {
        const selectedDate = new Date(e);
        let date = selectedDate.toLocaleDateString('en-GB');
        setState({ ...state, [name]: date });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader({ ...loader, submit: true });
        let data = { ...state };
        data = {
            ...data,
            integrationId: id,
            locationId: locId,
            accountId: aId
        }
        dispatch(onCreateBusinessPost(data, loader, setLoader, fetchPosts, handleClose))
    }

    useEffect(() => {
        setState((preData) => {
            preData.url = photos
        })
    }, [photos])

    const fetchPostDetails = () => {
        setLoader({ ...loader, fetch: true });
        let data = {
            id: type.id
        }
        dispatch(onFetchPostDetails(data, setState, setPhotos, loader, setLoader))
    }

    useEffect(() => {
        if (type.type === "edit") {
            fetchPostDetails()
        }
    }, [type.type])

    return (
        loader.fetch ?
            <div className="site-wrap">
                <div className="loader-sec">
                    <div className="loader" />
                </div>
            </div>
            :
            <form onSubmit={handleSubmit}>
                <h3><BsFileEarmarkText />Create Posts</h3>
                <div className='create-post-wrap' style={{ display: "block" }} >
                    <div className="row">
                        <div className="col-xxxl-6 col-xxl-6 col-xl-6 col-12 mb-xl-0 mb-5">
                            <div className='create-post-left' style={{ width: "100%" }}>
                                <PostImages
                                    photos={photos}
                                    setPhotos={setPhotos}
                                    type={"createPost"}
                                />
                                <div className='input-wrap'>
                                    <label htmlFor="">Offer Title
                                        <span className='tootText'><span className='tootText-icon'><BsExclamationCircle /></span> <span className='tootText-txt'>Add Info Here</span></span>
                                    </label>
                                    <input
                                        className='common-inp alt'
                                        type="text"
                                        placeholder='Offer Title'
                                        name='title'
                                        value={state.title}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className='input-wrap'>
                                    <label className='m-0 p-0' htmlFor="">Offer Date and Time</label>
                                    <div className='row'>
                                        <div className='col-lg-6'>

                                            <DatePicker
                                                className='common-inp alt mt-3'
                                                dateFormat='dd/MM/yyyy'
                                                name='fromDate'
                                                value={state.fromDate}
                                                onChange={(e) => handleChangeDate(e, "fromDate")}
                                                minDate={date.toDateString()}
                                            />
                                        </div>
                                        <div className='col-lg-6'>
                                            <select
                                                className='common-inp alt mt-3'
                                                name="fromTime"
                                                value={state.fromTime}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Start Time</option>
                                                <option value="00:00">00:00 AM</option>
                                                <option value="00:30">00:30 AM</option>
                                                <option value="1:00">1:00 AM</option>
                                                <option value="1:30">1:30 AM</option>
                                                <option value="2:00">2:00 AM</option>
                                                <option value="2:30">2:30 AM</option>
                                                <option value="3:00">3:00 AM</option>
                                                <option value="3:30">3:30 AM</option>
                                                <option value="4:00">4:00 AM</option>
                                                <option value="4:30">4:30 AM</option>
                                                <option value="5:00">5:00 AM</option>
                                                <option value="5:30">5:30 AM</option>
                                                <option value="6:00">6:00 AM</option>
                                                <option value="6:30">6:30 AM</option>
                                                <option value="7:00">7:00 AM</option>
                                                <option value="7:30">7:30 AM</option>
                                                <option value="8:00">8:00 AM</option>
                                                <option value="8:30">8:30 AM</option>
                                                <option value="9:00">9:00 AM</option>
                                                <option value="9:30">9:30 AM</option>
                                                <option value="10:00">10:00 AM</option>
                                                <option value="10:30">10:30 AM</option>
                                                <option value="11:00">11:00 AM</option>
                                                <option value="11:30">11:30 AM</option>
                                                <option value="12:00">12:00 PM</option>
                                                <option value="12:30">12:30 PM</option>
                                                <option value="13:00">1:00 PM</option>
                                                <option value="13:30">1:30 PM</option>
                                                <option value="14:00">2:00 PM</option>
                                                <option value="14:30">2:30 PM</option>
                                                <option value="15:00">3:00 PM</option>
                                                <option value="15:30">3:30 PM</option>
                                                <option value="16:00">4:00 PM</option>
                                                <option value="16:30">4:30 PM</option>
                                                <option value="17:00">5:00 PM</option>
                                                <option value="17:30">5:30 PM</option>
                                                <option value="18:00">6:00 PM</option>
                                                <option value="18:30">6:30 PM</option>
                                                <option value="19:00">7:00 PM</option>
                                                <option value="19:30">7:30 PM</option>
                                                <option value="20:00">8:00 PM</option>
                                                <option value="20:30">8:30 PM</option>
                                                <option value="21:00">9:00 PM</option>
                                                <option value="21:30">9:30 PM</option>
                                                <option value="22:00">10:00 PM</option>
                                                <option value="22:30">10:30 PM</option>
                                                <option value="23:00">11:00 PM</option>
                                                <option value="23:30">11:30 PM</option>
                                                <option value="12:00">12:00 PM</option>
                                                <option value="12:30">12:30 PM</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <DatePicker
                                                className='common-inp alt mt-3'
                                                dateFormat='dd/MM/yyyy'
                                                name='toDate'
                                                value={state.toDate}
                                                onChange={(e) => handleChangeDate(e, "toDate")}
                                                minDate={new Date(`${state.fromDate.split("/")[2]}-${state.fromDate.split("/")[1]}-${state.fromDate.split("/")[0]}`)}
                                            />
                                        </div>
                                        <div className='col-lg-6'>
                                            <select
                                                className='common-inp alt mt-3'
                                                name="toTime"
                                                value={state.toTime}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">End Time</option>
                                                <option value="00:00">00:00 AM</option>
                                                <option value="00:30">00:30 AM</option>
                                                <option value="1:00">1:00 AM</option>
                                                <option value="1:30">1:30 AM</option>
                                                <option value="2:00">2:00 AM</option>
                                                <option value="2:30">2:30 AM</option>
                                                <option value="3:00">3:00 AM</option>
                                                <option value="3:30">3:30 AM</option>
                                                <option value="4:00">4:00 AM</option>
                                                <option value="4:30">4:30 AM</option>
                                                <option value="5:00">5:00 AM</option>
                                                <option value="5:30">5:30 AM</option>
                                                <option value="6:00">6:00 AM</option>
                                                <option value="6:30">6:30 AM</option>
                                                <option value="7:00">7:00 AM</option>
                                                <option value="7:30">7:30 AM</option>
                                                <option value="8:00">8:00 AM</option>
                                                <option value="8:30">8:30 AM</option>
                                                <option value="9:00">9:00 AM</option>
                                                <option value="9:30">9:30 AM</option>
                                                <option value="10:00">10:00 AM</option>
                                                <option value="10:30">10:30 AM</option>
                                                <option value="11:00">11:00 AM</option>
                                                <option value="11:30">11:30 AM</option>
                                                <option value="12:00">12:00 PM</option>
                                                <option value="12:30">12:30 PM</option>
                                                <option value="13:00">1:00 PM</option>
                                                <option value="13:30">1:30 PM</option>
                                                <option value="14:00">2:00 PM</option>
                                                <option value="14:30">2:30 PM</option>
                                                <option value="15:00">3:00 PM</option>
                                                <option value="15:30">3:30 PM</option>
                                                <option value="16:00">4:00 PM</option>
                                                <option value="16:30">4:30 PM</option>
                                                <option value="17:00">5:00 PM</option>
                                                <option value="17:30">5:30 PM</option>
                                                <option value="18:00">6:00 PM</option>
                                                <option value="18:30">6:30 PM</option>
                                                <option value="19:00">7:00 PM</option>
                                                <option value="19:30">7:30 PM</option>
                                                <option value="20:00">8:00 PM</option>
                                                <option value="20:30">8:30 PM</option>
                                                <option value="21:00">9:00 PM</option>
                                                <option value="21:30">9:30 PM</option>
                                                <option value="22:00">10:00 PM</option>
                                                <option value="22:30">10:30 PM</option>
                                                <option value="23:00">11:00 PM</option>
                                                <option value="23:30">11:30 PM</option>
                                                <option value="12:00">12:00 PM</option>
                                                <option value="12:30">12:30 PM</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <hr className='mt-5' />

                                <div className='d-flex input-wrap'>
                                    <button type="button" className='theme-btn mx-2' style={{ width: "130px" }} onClick={handleClose}> <span> <GoArrowLeft />Back</span></button>
                                    {+auth.user.isClientAccount === 1 ? null :
                                        <button type="submit" className='theme-btn'><span>{loader.submit ? <> Creating <i className="fa fa-spinner fa-spin" /></> : "Create"}</span></button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-6 col-12 ">
                            <PostMobileView
                                state={state}
                            />
                        </div>
                    </div>

                </div>
            </form>
    )
}

export default CreatePost
