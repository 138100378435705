import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import facebookIcon from "../../../assets/images/icon/facebook.png";
import youtubeIcon from "../../../assets/images/integrations/google_icon.png"
import { AiOutlineClose } from 'react-icons/ai';
import { Modal } from 'react-bootstrap';
import SocialIcons from './SocialIcons';
import { onPublishVideo } from '../../../Redux/Actions/CommonActions';

const PublishModal = ({ curElem, show, handleClose, type }) => {
    const socials = useSelector(state => state.social)
    const dispatch = useDispatch()
    const [currSocial, setCurrSocial] = useState([]);
    const [selectIcon, setSelectIcon] = useState(false);
    const [socialData, setSocialData] = useState(false);
    const [loader, setLoader] = useState(false)
    const [postData, setPostData] = useState({
        title: '',
        description: '',
        video_url: "",
        integration_id: '',
        type: '',
        network: ''
    })

    const currentSocial = (social, type, icon) => {
        if (social.length > 0) {
            setCurrSocial(social);
            setSelectIcon(icon);
            setPostData({
                ...postData,
                type: type,
                integration_id: social[0].id,
                network: icon,
            });
        }
    }

    const onInputChange = (e) => {
        setPostData({ ...postData, [e.target.name]: e.target.value });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch(onPublishVideo(postData.type, postData, handleClose, setLoader))
    }

    useEffect(() => {
        if (socialData === false) {
            setSocialData(socials)
        }
    }, [socials]);

    useEffect(() => {
        if (!show) {
            setPostData({
                ...postData,
                title: '',
                description: '',
                video_url: "",
                integration_id: '',
                type: '',
                network: ''
            })
            setSelectIcon(false)
        }
    }, [show])


    useEffect(() => {
        setPostData({
            ...postData,
            video_url: curElem.type === "image" ? curElem.url : curElem.videoUrl
        })

    }, [curElem])
    return (

        <Modal className="theme-modal public_modal  px-2" show={show} onHide={handleClose} centered>
            <Modal.Body style={{ padding: "20px 0" }}>
                <span className="modal-close" onClick={handleClose}><AiOutlineClose /></span>
                <col-12 >
                    <div className="text-center ">
                        <p style={{ color: '#52f195', fontSize: "20px", fontWeight: "700", marginBottom: "20px", paddingBottom: "20px", borderBottom: "2px solid", }}>Publish this <span style={{ textTransform: "lowercase" }}>{curElem.type}</span></p>
                    </div>
                </col-12>
                <div>
                    <ul className='d-flex justify-content-around align-items-center'>
                        <li onClick={() => currentSocial(socialData.facebook, 'upload-to-facebook', 'facebook', curElem.url)} className='social_hover'>
                            <SocialIcons
                                icon={facebookIcon}
                                status={socials.facebook.length > 0 ? true : false}
                                name="Facebook"
                                selectIcon={selectIcon === "facebook" ? "select-social" : ''}
                            />
                        </li>
                        <li
                            onClick={() => currentSocial(socialData.google, 'upload-to-youtube', 'google', curElem.url)} className='social_hover'
                        >
                            <SocialIcons
                                icon={youtubeIcon}
                                status={socials.google.length > 0 ? true : false}
                                name="Google"
                                selectIcon={selectIcon === "google" ? "select-social" : ''}
                            />
                        </li>

                    </ul>

                    <div className="form-body mt-4">
                        <form className="mx-3" method="post" onSubmit={(e) => onFormSubmit(e)}>
                            <div className="form-group form-group-alt mb-2">
                                <label className="form-text text-muted">Title</label>
                                <input type="text" className="form-control text-light common-inp publish_text" required name="title" onChange={(e) => onInputChange(e)} placeholder="Enter title" />
                            </div>
                            <div className="form-group form-group-alt mb-2">
                                <label className="form-text text-muted mb-2">Description</label>
                                <textarea className="form-control text-light common-inp publish_text" required name="description" onChange={(e) => onInputChange(e)} placeholder="Enter description" />
                            </div>
                            <div className="form-group form-group-alt mb-2">
                                <label className="form-text text-muted">Account type</label>
                                <select
                                    className="form-control common-inp publish_text"
                                    required name="integration_id"
                                    onChange={(e) => onInputChange(e)}
                                >
                                    <option disabled selected>Select account</option>
                                    {
                                        currSocial.length > 0 ?
                                            currSocial.map((item, index) => {
                                                return (
                                                    <option key={index} style={{ backgroundColor: "#000", color: "#fff", padding: "20px" }} value={item.id}>{item?.username}</option>
                                                )
                                            })
                                            : ''
                                    }
                                </select>
                            </div>
                            <div className="text-center mt-4">
                                <button type="submit" className="theme-btn" disabled={loader}>
                                    <span>
                                        {loader ? <>
                                            Publishing
                                            <i className="fa fa-spinner fa-spin mx-2" />  </> : 'Publish'}
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </Modal.Body>
        </Modal>
    )
}

export default PublishModal