import React, { useEffect, useState } from 'react'
import LeftPanel from './LeftPanel'
import ReviewCard from './ReviewCard'
import { onFetchSettingsData } from '../../../../Redux/Actions/ReviewAction'
import { useDispatch } from 'react-redux'

const Reviews = ({ currentTodo, reviewData, setReviewData, filterData, setFilterData, loader, integrationId, locationData, type }) => {
   
    return (
        <div className="review-container">
            <div className="review-left">
                <LeftPanel
                    reviewData={reviewData}
                    filterData={filterData}
                    setFilterData={setFilterData}
                />
            </div>
            <div className="review-right review_right_warp">
                {currentTodo.length > 0 ?
                    currentTodo.map((curElem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <ReviewCard
                                    curElem={curElem}
                                    index={index}
                                    reviewData={reviewData}
                                    setReviewData={setReviewData}
                                    integrationId={integrationId}
                                    type={type}
                                />
                            </React.Fragment>
                        )
                    })
                    :
                    <div className="text-center">
                        {loader.fetch ? <i className="fa fa-spin fa-spinner spin-style" /> : "No review found."}
                    </div>
                }
            </div>
        </div>
    )
}

export default Reviews
