import React from "react";
import TitleBar from "../../../CommonComponents/TitleBar";
import { Link } from "react-router-dom";
import AiEmailTable from "./AiEmailTable";
import { useSelector } from "react-redux";

const AiEmail = () => {

    const auth = useSelector(state => state.auth)

    return (
        <>
            <TitleBar title="Ai Email" />

            <div className="site-wrap">
                <div className="site-container">
                    <section>
                        <div className='d-flex justify-content-between w-100 h-100 align-items-center pt-5 pb-3' >
                            <h5 className="">Your AI email campaigns</h5>
                            {+auth.user.isClientAccount === 1 ? null :
                                <Link to={`/lead/create-ai-emails`} className='theme-btn'>
                                    <span>Generate AI email</span>
                                </Link>
                            }
                        </div>
                        <AiEmailTable />
                    </section>
                </div>
            </div>
        </>
    )
}

export default AiEmail;