import { GoogleMap, Marker } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import {
  BsStarFill,
  BsStarHalf,
  BsShop,
  BsEnvelope,
  BsTelephone,
  BsGlobeAmericas,
  BsPeople,
  BsCameraVideo,
  BsCardImage,
} from "react-icons/bs";
import { FaRegAddressCard } from "react-icons/fa";
import {
  IoLocationOutline,
  IoTimeOutline,
} from "react-icons/io5";
import ReviewText from "./ReviewText";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Modal from "react-bootstrap/Modal";
import { AiOutlineClose } from "react-icons/ai";
import { themeColor } from "../../../../Global/Global";
import { BiSolidReport } from "react-icons/bi";
import GenerateReportModal from "../ManageLeads/GenerateReportModal";

const LeadCard = ({ curElem }) => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [galleryImages, setGalleryImages] = useState([]);
  const [startIndexImg, setStartIndexImg] = useState(0);
  const [galleryShow, setGalleryShow] = useState(false);
  const [moreToggle, setMoreToggle] = useState({
    emails: 3,
    contacts: 3,
    videoLink: 3
  })
  const handleGallery = () => {
    if (curElem.photos?.length > 0) {
      let arr = curElem.photos.map((url) => {
        return {
          original: url,
          thumbnail: url,
        };
      });
      setGalleryImages(arr);
    }
  };

  const handleGalleryShow = (index) => {
    setGalleryShow(true);
    setStartIndexImg(index);
  };

  const handleGalleryClose = () => {
    setGalleryShow(false);
  };

  const handleReadMore = (type) => {
    if (type === "emails") {
      setMoreToggle((preData) => {
        return {
          ...moreToggle,
          emails: preData.emails === 3 ? curElem?.emails?.length : 3
        }
      })
    } else if (type === "videoLink") {
      setMoreToggle((preData) => {
        return {
          ...moreToggle,
          videoLink: preData.videoLink === 3 ? curElem?.videoLink?.length : 3
        }
      })
    } else {
      setMoreToggle((preData) => {
        return {
          ...moreToggle,
          contacts: preData.contacts === 3 ? curElem?.contact?.length : 3
        }
      })
    }
  }
  console.log(curElem, "curElemcurElem")
  useEffect(() => {
    if (curElem?.photos) {
      handleGallery();
    }
  }, [curElem]);

  return (
    <div className="col-lg-6 mb-4">
      <div className="lead-single">
        <div className="lead-head">
          <div className="lead-imgx">
            <img src={curElem.icon} />
            <p>{curElem.businessName}</p>
          </div>
          <div className="lead-ratings">
            <div className="stars">
              {+curElem?.rating?.length > 0
                ? [...Array(Math.floor(+curElem.rating))].map((star, index) => (
                  <BsStarFill key={index} />
                ))
                : ""}
              {+curElem.rating % 1 !== 0 ? <BsStarHalf /> : ""}
            </div>
            <p className="mx-1">({+curElem.rating})</p>
          </div>
        </div>
        <div className="lead-img">
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "400px" }}
            center={curElem.location ? JSON.parse(curElem.location) : ""}
            zoom={15}
          >
            {
              <Marker
                position={curElem.location ? JSON.parse(curElem.location) : ""}
              />
            }
          </GoogleMap>
        </div>
        <div className="lead-details">
          <ul>
            <li>
              <span>
                <BsShop /> GBP Listing
              </span>
              <span className="position-relative">
                <a className="linkBtn" target="_blank" href={curElem.mapUrl}>
                  {curElem.mapUrl ? "view" : "N/A"}
                </a>
                <div className="reoprtIcon" onClick={handleShow}><BiSolidReport color={themeColor} /></div>
              </span>
            </li>
            <li>
              <span>
                <BsEnvelope /> Email
              </span>
              {curElem.websiteLoader ? (
                <i
                  className="fa fa-spinner fa-spin mr-2 my-2"
                  style={{ color: themeColor, fontSize: 15 }}
                />
              ) : (
                <span className="open-hrs">
                  {curElem?.emails && curElem.emails.length > 0 ?
                    Array.isArray(curElem.emails) ?
                      curElem.emails.map((email, index) => <div key={index}>{email}</div>).slice(0, moreToggle.emails)
                      :
                      <div>{curElem?.emails}</div>
                    : "N/A"
                  }
                  {curElem?.emails && curElem.emails.length > 3 ?
                    Array.isArray(curElem.emails) ?
                      <span className='review_read_more'>
                        <span style={{ color: "#0d6efd", cursor: "pointer" }} onClick={() => handleReadMore("emails")}>{moreToggle.emails > 3 ? "ReadLess" : "ReadMore..."}</span>
                      </span>
                      : ""
                    : ""
                  }
                </span>
              )}
            </li>
            <li>
              <span>
                <BsTelephone /> Phone
              </span>
              <span>{curElem.phoneNumber ? curElem.phoneNumber : "N/A"}</span>
            </li>
            <li>
              <span>
                <FaRegAddressCard /> Address
              </span>
              {curElem.loader ?
                <i
                  className="fa fa-spinner fa-spin mr-2 my-2"
                  style={{ color: themeColor, fontSize: 15 }}
                />
                :
                <span>{curElem.address ? curElem.address : "N/A"}</span>
              }
            </li>
            {/* <li>
              <span>
                <IoBusinessSharp /> Business Status
              </span>
              <span>
                <small
                  className={`business-stat ${curElem.businessStatus === "OPERATIONAL"
                    ? "green"
                    : curElem.businessStatus === "CLOSED"
                      ? "red"
                      : "orange"
                    }`}
                >
                  {curElem.businessStatus ? curElem.businessStatus : "N/A"}
                </small>
              </span>
            </li> */}
            <li>
              <span>
                <BsPeople /> Reviews
              </span>
              <span>{curElem.userRatingsTotal}</span>
            </li>
            {/* <li>
              <span>
                <IoPricetagOutline /> Price Level
              </span>
              <small
                style={{
                  backgroundColor: `${curElem.priceLevel
                    ? +curElem.priceLevel >= 4
                      ? "red"
                      : +curElem.priceLevel === 3
                        ? "green"
                        : "orange"
                    : ""
                    }`,
                }}
                className="business-stat"
              >
                {curElem.priceLevel
                  ? +curElem.priceLevel >= 4
                    ? "Very Expensive"
                    : +curElem.priceLevel < 4 && +curElem.priceLevel >= 2
                      ? "Moderate"
                      : "Low"
                  : "N/A"}
              </small>
            </li> */}
            {/* <li>
              <div className="d-flex align-items-start  ">
                <span style={{ minWidth: "151px" }}>
                  <TiTag /> Tags
                </span>
                <div className="">
                  {curElem?.tag?.length > 0
                    ? curElem.tag.map((tag, index) => {
                      return (
                        <small
                          className={`business-stat ${index === 0 ? "me-2" : "me-2"} mb-2 bg-primary`}
                          key={index}
                        >
                          {tag}
                        </small>
                      );
                    })
                    : ""}
                </div>
              </div>
            </li> */}

            <li>
              <span>
                <IoLocationOutline /> Landmark
              </span>
              <span>{curElem.landMark ? curElem.landMark : "N/A"}</span>
            </li>
            <li className="for-web">
              <span>
                <BsGlobeAmericas /> Website
              </span>
              {curElem.loader ?
                <i
                  className="fa fa-spinner fa-spin mr-2 my-2"
                  style={{ color: themeColor, fontSize: 15 }}
                />
                :
                <span>
                  {curElem?.website ?
                    <a className="linkBtn" target="_blank" href={curElem.website}>
                      visit
                    </a>
                    :
                    "N/A"
                  }
                </span>
              }
            </li>

            <li className="align-items-start">
              <span>
                <IoTimeOutline /> Opening Hours
              </span>
              {curElem.loader ?
                <i
                  className="fa fa-spinner fa-spin mr-2 my-2"
                  style={{ color: themeColor, fontSize: 15 }}
                />
                :
                <span className="open-hrs">
                  {curElem?.openingHours?.formattedData?.length > 0 ?
                    curElem?.openingHours?.formattedData?.map((hours, index) => {
                      if (hours) {
                        return (
                          <div key={index}>
                            {Object.entries(hours).map(([day, hour]) => (
                              <div key={day}>{day}: {hour}</div>
                            ))}
                          </div>
                        );
                      } else {
                        return <div key={index}> "N/A"</div>
                      }
                    })
                    : "N/A"
                  }

                </span>
              }
            </li>

            <li>
              <span>
                <BsTelephone /> Contact No
              </span>
              {curElem.websiteLoader ? (
                <i
                  className="fa fa-spinner fa-spin mr-2 my-2"
                  style={{ color: themeColor, fontSize: 15 }}
                />
              ) : (
                <span className="open-hrs">
                  {curElem?.contact && curElem.contact.length > 0 ?
                    Array.isArray(curElem.contact) ?
                      curElem.contact.map((number, index) => <div key={index}>{number}</div>).slice(0, moreToggle.contacts)
                      :
                      <div>{curElem.contact}</div>
                    :
                    "N/A"
                  }
                  {curElem?.contact && curElem.contact.length > 3 ?
                    Array.isArray(curElem.contact) ?
                      <span className='review_read_more'>
                        <span style={{ color: "#0d6efd", cursor: "pointer" }} onClick={() => handleReadMore("contacts")}>{moreToggle.contacts > 3 ? "ReadLess" : "ReadMore..."}</span>
                      </span>
                      : ""
                    : ""
                  }
                </span>
              )}
            </li>
            <li>
              <span>
                <BsCameraVideo /> Video
              </span>
              {curElem.websiteLoader ? (
                <i
                  className="fa fa-spinner fa-spin mr-2 my-2"
                  style={{ color: themeColor, fontSize: 15 }}
                />
              ) : (
                <span className="open-hrs">
                  {curElem?.videoLink && curElem.videoLink.length > 0 ?
                    Array.isArray(curElem.videoLink) ?
                      curElem.videoLink.map((url, index) => <div key={index}>{url}</div>).slice(0, moreToggle.videoLink)
                      :
                      <div>{curElem.videoLink}</div>
                    :
                    "N/A"
                  }
                  {curElem?.videoLink && curElem.videoLink.length > 3 ?
                    Array.isArray(curElem.videoLink) ?
                      <span className='review_read_more'>
                        <span style={{ color: "#0d6efd", cursor: "pointer" }} onClick={() => handleReadMore("videoLinks")}>{moreToggle.videoLink > 3 ? "ReadLess" : "ReadMore..."}</span>
                      </span>
                      : ""
                    : ""
                  }
                </span>
              )}
            </li>


            <li className="for-web">
              <span>
                <BsCardImage /> Images
              </span>
              {curElem.loader ?
                <i
                  className="fa fa-spinner fa-spin mr-2 my-2"
                  style={{ color: themeColor, fontSize: 15 }}
                />
                :
                <span>
                  {!curElem?.photos?.length > 0 && "N/A"}
                </span>
              }
            </li>

            <div className="web-images">
              {curElem.loader ? "" :
                curElem?.photos?.map((img, index) => {
                  return (
                    <div className="web-images-single" key={index}>
                      <img
                        src={img}
                        alt=""
                        onClick={() => handleGalleryShow(index)}
                      />
                    </div>
                  );
                })
              }
            </div>
            <div>
              <Modal
                show={galleryShow}
                className="theme-modal gallery-modal"
                onHide={handleGalleryClose}
              >
                <Modal.Body>
                  <div
                    className="modalClose text-end"
                    onClick={handleGalleryClose}
                  >
                    <AiOutlineClose
                      style={{ fontSize: "20px", marginBottom: "-55px" }}
                    />
                  </div>
                  <ImageGallery
                    items={galleryImages}
                    startIndex={startIndexImg}
                  />
                </Modal.Body>
              </Modal>
            </div>


            <li className="for-web">
              <span>
                <BsPeople /> Recent Reviews
              </span>
              {curElem.loader ?
                <i
                  className="fa fa-spinner fa-spin mr-2 my-2"
                  style={{ color: themeColor, fontSize: 15 }}
                />
                :
                <span>{!curElem?.review?.length > 0 && "N/A"}</span>
              }
            </li>

            <div className="card-review">
              {!curElem.loader && curElem?.review?.length > 0
                ? curElem.review.map((review, index) => {
                  return (
                    <React.Fragment key={index}>
                      <ReviewText review={review} />
                    </React.Fragment>
                  );
                })
                : ""}
            </div>
          </ul>
        </div>
      </div>
      <GenerateReportModal
        show={show}
        handleClose={handleClose}
        curElem={curElem}
      />
    </div >
  );
};

export default LeadCard;
