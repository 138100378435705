import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { BsXLg } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { themeColor } from '../../../../Global/Global'
import { onUploadMedia } from '../../../../Redux/Actions/CommonActions'
import dummyImg from "../../../../assets/images/dummy.jpg"
import { FiUpload } from 'react-icons/fi'
import { onGenerateLeadReport } from '../../../../Redux/Actions/LeadAction'

const GenerateReportModal = ({ show, handleClose, curElem }) => {
    const allowedExt = ['image/png', 'image/jpg', 'image/jpeg']

    const dispatch = useDispatch();
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState({
        submit: false,
        upload: false,
    })
    const [state, setState] = useState({
        email: "",
        phone: "",
    });
    const [logo, setLogo] = useState("")

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({ ...state, [name]: value })
    };


    const fetchImgMedia = (url) => {
        setLogo(url)
    }

    const onInputChange = (e) => {
        const formData = new FormData()
        if (e.target.files.length > 0) {
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < 5000000) {
                    formData.append('upload_type', "images")
                    formData.append('file', e.target.files[0])
                    dispatch(onUploadMedia(formData, fetchImgMedia, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for images is 5MB!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }
    const handleSubmit = (e) => {
        setLoader({ ...loader, submit: true })
        e.preventDefault();
        let data = { ...state, leadId: curElem.id, logo: logo }
        dispatch(onGenerateLeadReport(data, loader, setLoader, handleClose))
        setState({
            ...state,
            email: "",
            phone: "",
        })
        setLogo("")
    };

    useEffect(() => {
        if (show) {
            setState({
                ...state,
                email: curElem.emails.length > 0 ? typeof (curElem.emails) === "string" ? JSON.parse(curElem.emails)[0] : curElem.emails[0] : "",
                phone: curElem.phoneNumber ? curElem.phoneNumber : curElem.contact.length > 0 ? curElem.contact[0] : ""
            })
            setLogo("")
        }
    }, [show])

    return (
        <Modal className="theme-modal" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div className="modalClose" onClick={handleClose}><BsXLg /></div>
                <h5>Generate report</h5>
                <form action="" onSubmit={handleSubmit}>
                    <div className="input-outer mt-1">
                        <label className="mb-2" htmlFor="">Add logo</label>
                        <div className="d-flex justify-content-center align-items-center">
                            <div className='upload-box me-2' style={{ background: "#1d1b41a1", width: "100%" }}>
                                {loader.upload ?
                                    <div className='percent-loader-box' style={{ fontSize: 16 }}>
                                        {percent}%
                                    </div> :
                                    <>
                                        <FiUpload />
                                        <p>Upload JPG, JPEG & PNG</p>
                                        <input
                                            type="file"
                                            onChange={onInputChange}
                                            required={logo ? false : true}
                                        />
                                    </>
                                }

                            </div>
                            <div style={{ width: 160, height: 120, position: "relative" }}>
                                <img src={logo ? logo : dummyImg} alt="" style={{ height: "100%", width: "100%", borderRadius: 10, objectFit: "contain" }} />
                                {loader.upload ?
                                    <div style={{ position: "absolute", top: 0, display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }}>
                                        <i className="fa fa-spin fa-spinner" style={{ fontSize: 30, color: themeColor }} />
                                    </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                    <div className="input-outer mt-4">
                        <input
                            className="common-inp alt"
                            type="text"
                            placeholder="Enter email address"
                            name='email'
                            value={state.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="input-outer mt-4">
                        <input
                            className="common-inp alt"
                            type="text"
                            placeholder="Enter phone number"
                            name='phone'
                            value={state.phone}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="input-outer mt-4">
                        <div className="row">
                            <div className="col-6">
                                <button type='button' className="theme-btn bdr full" onClick={handleClose}><span>Cancel</span></button>
                            </div>
                            <div className="col-6">
                                <button type="submit" className="theme-btn full">
                                    <span>{loader.submit ? <> Generating <i className="fa fa-spinner fa-spin ms-1" /></> : "Generate"}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default GenerateReportModal
