import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";

export const onSearchLeads = (data, setMainLeadData, setLeadData, state, setState, loader, setLoader, type) => (dispatch, getState) => {
    commonAxios("search-leads", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data.data]
                arr = arr.map(element => {
                    return {
                        ...element,
                        openingHours: (element.openingHours && typeof element.openingHours === 'string') ? JSON.parse(element.openingHours) : (element.openingHours || []),
                        tag: element.tag ? JSON.parse(element.tag) : [],
                        photos: (element.photos && typeof element.photos === 'string') ? JSON.parse(element.photos) : (element.photos || []),
                        review: (element.review && typeof element.review === 'string') ? JSON.parse(element.review) : (element.review || []),
                        loader: true,
                        websiteLoader: true
                    };
                });

                let firstFive = arr.splice(0, 5)
                setLeadData(firstFive)
                setMainLeadData(arr);
                setState({ ...state, fId: res.data.fId, next_page: res.data.next_page_token });

                firstFive.forEach(element => {
                    let data = {
                        id: element.id,
                        mapUrl: element.mapUrl,
                        proxy: "",
                    };
                    dispatch(onGetLeadDetails(data, false, setLeadData, false));
                })
            } else {
                setLeadData([])
            }
            setLoader({ ...loader, submit: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, submit: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onCreateLeadFolder = (data, loader, setLoader, handleClose, setMainCheckedArr) => (dispatch, getState) => {
    commonAxios("create-folder", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(onFetchLeadFolder(handleClose, loader, setLoader, res.msg))
                setMainCheckedArr((draft) => {
                    draft.unshift([])
                })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, submit: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onFetchLeadFolder = (handleClose, loader, setLoader, message, setSweet) => (dispatch, getState) => {
    commonAxios("fetch-folder", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "SET_LEAD_FOLDER_DATA", payload: { data: res.data.folders, loader: false } })
            } else {
                dispatch({ type: "SET_LEAD_FOLDER_DATA", payload: { data: false, loader: false } })
            }
            if (handleClose) {
                handleClose();
                dispatch(setAlert(message, "success"))
            }
            if (setLoader) {
                setLoader({ ...loader, submit: false });
            }
            if (setSweet) {
                setSweet({
                    enable: false,
                    id: false,
                    confirmButtonName: "Delete",
                    loader: false
                })
            }
        }).catch((err) => {
            console.log(err)
            if (setLoader) {
                setLoader({ ...loader, submit: false });
            }
            if (setSweet) {
                setSweet({
                    enable: false,
                    id: false,
                    confirmButtonName: "Delete",
                    loader: false
                })
            }
            dispatch({ type: "SET_LEAD_FOLDER_DATA", payload: { data: false, loader: false } })
            dispatch(setAlert(err.message, "danger"))
        })
}
export const onFetchFolderDataById = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-leads", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data];
                arr = arr.map(element => {
                    return {
                        ...element,
                        openingHours: (element.openingHours && typeof element.openingHours === 'string') ? JSON.parse(element.openingHours) : (element.openingHours || []),
                        tag: element.tag ? JSON.parse(element.tag) : [],
                        photos: (element.photos && typeof element.photos === 'string') ? JSON.parse(element.photos) : (element.photos || []),
                        review: (element.review && typeof element.review === 'string') ? JSON.parse(element.review) : (element.review || []),
                        contact: element.contact ? JSON.parse(element.contact) : [],
                        videoLink: element.videoLink ? JSON.parse(element.videoLink) : [],
                        emails: element.emails ? JSON.parse(element.emails) : [],
                        loader: false,
                        websiteLoader: false
                    };
                });
                setState(arr);
            }
            setLoader({ ...loader, fetch: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, fetch: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onEditLeadFolder = (data, loader, setLoader, handleClose) => (dispatch, getState) => {
    commonAxios("edit-folder-name", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "CHANGE_FOLDER_NAME", payload: { data } })
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            handleClose();
            setLoader({ ...loader, submit: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, submit: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onDeleteLeadFolder = (url, data, state, index, setSweet, mainCheckedArr, setMainCheckedArr) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (data.type === "all") {
                    dispatch(onFetchLeadFolder(false, false, false, res.msg, setSweet))
                    setMainCheckedArr([])
                } else if (data.type === "lead") {
                    let lead = state[index].leads.filter((lead) => {
                        return +lead.id !== +data.id[0]
                    })

                    let checkArray = mainCheckedArr.filter((lead) => {
                        return +lead !== +data.id[0]
                    })
                    setMainCheckedArr((draft) => {
                        draft[index] = checkArray
                    })

                    dispatch({ type: "DELETE_FOLDER_LEAD", payload: { data: lead, index: index } })
                } else {
                    let folder = state.filter((curElem) => {
                        return +curElem.fId !== +data.id
                    })
                    setMainCheckedArr((draft) => {
                        draft[index] = []
                    })
                    dispatch({ type: "SET_LEAD_FOLDER_DATA", payload: { data: folder, loader: false } })
                }
                if (data.type !== "all") {
                    dispatch(setAlert(res.msg, "success"))
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            if (data.type !== "all") {
                setSweet({
                    enable: false,
                    id: false,
                    confirmButtonName: "Delete",
                    loader: false
                })
            }
        }).catch((err) => {
            console.log(err)
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        })
}

export const onMoveLead = (data, loader, setLoader, handleClose, index, mainCheckedArr, setMainCheckedArr) => (dispatch, getState) => {
    commonAxios("move-leads", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (data.id.length > 1 || index === undefined) {
                    setMainCheckedArr([]);
                    dispatch(onFetchLeadFolder(handleClose, loader, setLoader, res.msg))
                } else {
                    let checkArray = mainCheckedArr.filter((lead) => {
                        return +lead !== +data.id[0]
                    })
                    setMainCheckedArr((draft) => {
                        draft[index] = checkArray
                    })
                    dispatch({ type: "MOVE_FOLDER_LEAD", payload: { index, data } })
                    dispatch(setAlert(res.msg, "success"))
                    setLoader({ ...loader, fetch: false });
                    handleClose();
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setLoader({ ...loader, fetch: false });
                handleClose();
            }
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, fetch: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onAddManualLead = (data, setState, loader, setLoader, fetchAllFolder) => (dispatch, getState) => {
    commonAxios("add-lead", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                setState({
                    fId: "",
                    name: "",
                    emails: "",
                    phoneNumber: ""
                });
                fetchAllFolder();
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, submit: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, submit: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onLeadStatusChange = (data, index, leadFolderData) => (dispatch, getState) => {
    let leadIndex = leadFolderData[index].leads.findIndex((lead) => +lead.id === +data.id)
    dispatch({ type: "CHANGE_FOLDER_LEAD_STATUS", payload: { index, leadIndex, status: data.status } })

    commonAxios("change-lead-status", data, dispatch, getState().auth.token)
        .then((res) => {

        }).catch((err) => {
            console.log(err)
        })
}

export const onUploadLeadFile = (data, setState, loader, setLoader, fetchAllFolder) => (dispatch, getState) => {
    commonAxios("add-lead-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({
                    fId: "",
                    file: ""
                });
                fetchAllFolder();
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, upload: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, upload: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onFetchEmailCampaigns = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-contact-emails", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data);
            } else {
                setState([]);
            }
            setLoader({ ...loader, fetch: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, fetch: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onFetchEmailTemplates = (data, setState) => (dispatch, getState) => {
    commonAxios("fetch-email-template", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data);
            } else {
                setState([]);
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onSaveContactLead = (data, loader, setLoader, setIsSave) => (dispatch, getState) => {
    commonAxios("create-contact", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                setIsSave(true)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, save: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, save: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onSendContactLead = (data, loader, setLoader, navigator) => (dispatch, getState) => {
    commonAxios("send-leads-email", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigator("/lead/contact-leads")
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, submit: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, submit: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onTestContactLead = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("test-email-contact", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, test: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, test: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onSaveAiEmail = (data, loader, setLoader, navigator) => (dispatch, getState) => {
    commonAxios("ai-email", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigator("/lead/ai-email")
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, submit: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, submit: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onGenerateAiEmail = (data, state, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("generate-ai-email", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({
                    ...state,
                    subject: res.data.subject,
                    body: res.data.body
                })
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, generate: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, generate: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onFetchAiEmailCampaigns = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-ai-email", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data);
            } else {
                setState([]);
            }
            setLoader({ ...loader, fetch: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, fetch: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onFetchAiEmailDetails = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-email", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = res.data[0]
                let emailOptions = []
                if (obj.email.length > 0) {
                    emailOptions = JSON.parse(obj.email).map((ele) => {
                        return (
                            { value: ele, label: ele }
                        )
                    })
                }
                setState({
                    id: obj.id,
                    fId: obj.fId,
                    leadId: obj.leadId,
                    email: emailOptions,
                    category: obj.category,
                    phoneNumber: obj.phoneNumber,
                    description: obj.description,
                    operation: obj.operation,
                    address: obj.address,
                    searchServiceKeyword: obj.searchServiceKeyword,
                    subject: obj.subject,
                    body: obj.body,
                })
            }
            setLoader({ ...loader, fetch: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, fetch: false });
            dispatch(setAlert(err.message, "danger"))
        })
}

export const onGetContactInfo = (data, leadData, setLeadData, setLoader) => (dispatch, getState) => {
    commonAxios("scrap-website-url", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setLeadData(prevLeadData => {
                    return prevLeadData.map(lead => {
                        if (+lead.id === +data.id) {
                            return {
                                ...lead,
                                contact: res.data.contactNumber,
                                videoLink: res.data.videoUrls,
                                emails: res.data.emails,
                                websiteLoader: false
                            };
                        }
                        return lead;
                    });
                });
            }
            if (setLoader) {
                setLoader(false);
            }
        }).catch((err) => {
            console.log(err)
            if (setLoader) {
                setLoader(false);
            }
        })
}

export const onGetLeadDetails = (data, leadData, setLeadData, setLoader) => (dispatch, getState) => {
    commonAxios("search-lead-info", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                const getWebsiteData = (id, website) => {
                    let data = {
                        id: id,
                        url: website,
                        proxy: "",
                    };
                    dispatch(onGetContactInfo(data, false, setLeadData, false));
                }
                setLeadData(prevLeadData => {
                    return prevLeadData.map(lead => {
                        if (+lead.id === +data.id) {
                            const updatedLead = {
                                ...lead,
                                review: res.data?.data?.comments,
                                photos: res.data?.data?.photos,
                                website: res.data?.data?.website,
                                openingHours: res?.data.data?.openingHours,
                                address: res.data?.data?.address,
                                loader: false,
                            };
                            if (res.data?.data?.website) {
                                getWebsiteData(+data.id, res.data?.data?.website);
                            } else {
                                updatedLead.websiteLoader = false;
                            }
                            return updatedLead;
                        }
                        return lead;
                    });
                });
            }

            if (setLoader) {
                setLoader(false);
            }
        }).catch((err) => {
            console.log(err)
            if (setLoader) {
                setLoader(false);
            }
        })
}

export const onGenerateLeadReport = (data, loader, setLoader, handleClose) => (dispatch, getState) => {
    commonAxios("lead-report", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                window.open(res.data, "_blank")
                handleClose()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, submit: false });
        }).catch((err) => {
            console.log(err)
            setLoader({ ...loader, submit: false });
        })
}

export const onRemoveLeadFolderData = () => (dispatch, getState) => {
    dispatch({ type: "UNMOUNT_LEAD_FOLDER_DATA" })
}
