import React, { useCallback, useEffect, useState } from "react";
import TitleBar from "../../../CommonComponents/TitleBar";
import Modal from 'react-bootstrap/Modal';

import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import WidgetStyleWindow from "../WidgetStyleWindow";
import { onChangeOptinBackgroundColor, onChangeOptinBorderColor, onFetchOptinWidget, onOptinChange, onOptinChangeText, onOptinToggleCheck, removeOptinWidget } from "../../../../Redux/Actions/WidgetActions";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import FloatingWindow from "./StyleComponent/FloatingWindow";
import { fetchData, onUploadMedia } from "../../../../Redux/Actions/CommonActions";
import WidgetColor from "../WebsiteWidget/StyleComponent/WidgetColor";
import ImageSection from "../WebsiteWidget/StyleComponent/ImageSection";
import WindowStyle from "../WebsiteWidget/StyleComponent/Style/WindowStyle";
import { setAlert } from "../../../../Redux/Actions/AlertActions";
import { BsCloudUpload } from "react-icons/bs";
import Swal from "sweetalert2";
import dummyImage from "../../../../assets/images/dummy.jpg"


const OptionStyle = () => {
    const dispatch = useDispatch()
    const location = useLocation()

    const optin = useSelector(state => state.widget.optinData)
    const autoresponder = useSelector(state => state.social.autoResponder)
    const { id } = queryString.parse(location.search)

    const [percent, setPercent] = useState(0)

    let allowedType = ['image/png', 'image/jpg', 'image/jpeg']

    const [animData, setAnimData] = useState([])
    const [loader, setLoader] = useState(true)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleOpenPopup = (type) => {
        dispatch(onOptinChange("position", type))
        handleShow()
    }



    const handleChangeToogle = useCallback(
        (e) => {
            const { name, checked } = e.target
            dispatch(onOptinToggleCheck(name, checked))
        },
        [onOptinToggleCheck]
    );

    const handleChangeText = (e) => {
        const { name, value } = e.target
        if (name === "headline" && value.length > 60) {
            dispatch(setAlert("Your field exceeds the maximum character limit.", "danger"))
        } else if (name === "description" && value.length > 150) {
            dispatch(setAlert("Your field exceeds the maximum character limit.", "danger"))
        } else {
            dispatch(onOptinChangeText(name, value))
        }

    }

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onOptinChange(name, value))
    }

    const handleChangeColor = (color, type) => {
        if (type === "color") {
            type = "background"
            color = {
                target: {
                    value: color
                }
            }
        }

        if (type === "border") {
            dispatch(onChangeOptinBorderColor(color))
        } else if (type === "background") {
            dispatch(onChangeOptinBackgroundColor(color))
        }
    }

    const selectImage = (url) => {
        let obj = {
            target: {
                name: "src",
                value: url
            }
        }
        handleChangeColor(obj, "background")
    }


    const onLoadFile = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0]
            if (allowedType.includes(file.type)) {
                if (file.size < 5000000) {
                    let formData = new FormData();
                    formData.append('file', file);
                    formData.append('upload_type', "images");
                    setLoader(true)
                    dispatch(onUploadMedia(formData, selectImage, loader, setLoader, setPercent, "image"))

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for images is 5MB!',
                        confirmButtonColor: "#4944a7"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: "#4944a7"
                })
            }
        }
    }



    const fetchAnimation = () => {
        dispatch(fetchData("fetch-animation", {}, setAnimData, false, false, false, "shortDataLg"))
    }


    const fetchWidget = () => {
        let data = { id }
        setLoader(true)
        dispatch(onFetchOptinWidget(data, setLoader))
    }

    useEffect(() => {
        if (id) {
            fetchWidget()
            return () => {
                dispatch(removeOptinWidget())
            }
        }
    }, [id])

    useEffect(() => {
        fetchAnimation()
    }, [])

    return (
        loader && !optin ?

            <div className="site-wrap">
                <div className="loader-sec">
                    <div className="loader" />
                </div>
            </div>
            :
            <>
                <TitleBar title="Optin Widget" />

                <WidgetStyleWindow
                    handleOpenPopup={handleOpenPopup}
                    position={optin.data.position}
                    title={"Optin Widget"}
                    description={"Select a widget style below."}
                />


                <Modal className="widgetModal" show={show} onHide={handleClose}>
                    <Modal.Body>
                        <div className="widget_elements_wrapper" style={{ position: 'relative' }}>
                            <div className="widget-elements">
                                <div className="widget-elements-title">
                                    <div className="widgetModal-close3" onClick={handleClose}><IoClose /></div>
                                    <h6>Widget Headline</h6>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name='headline'
                                            checked={optin.data.headline.enable}
                                            onChange={handleChangeToogle}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                                <div className="input-outer">
                                    <input
                                        className="common-inp alt"
                                        type="text"
                                        name='headline'
                                        placeholder="Would you recommend us?"
                                        value={optin.data.headline.text}
                                        onChange={handleChangeText}
                                    />
                                </div>

                                <hr />

                                <div className="widget-elements-title">
                                    <h6>Widget Description</h6>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name='description'
                                            checked={optin.data.description.enable}
                                            onChange={handleChangeToogle}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                                <div className="input-outer">
                                    <textarea
                                        placeholder="Your feedback helps us improve! If you have a second, please leave a review on any of the platforms below. It only takes 30 seconds. Thank you!."
                                        className="common-inp alt"
                                        name="description"
                                        value={optin.data.description.text}
                                        onChange={handleChangeText}
                                    />
                                </div>

                                <hr />

                                <div className="widget-elements-title">
                                    <h6>Name</h6>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name='name'
                                            checked={optin.data.name.enable}
                                            onChange={handleChangeToogle}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                                {optin.data.name.enable ?
                                    <div className="input-outer">
                                        <input
                                            className="common-inp alt"
                                            type="text"
                                            placeholder="Enter Name"
                                            name="name"
                                            value={optin.data.name.text}
                                            onChange={handleChangeText}
                                        />
                                    </div> : null}

                                <hr />

                                <div className="widget-elements-title">
                                    <h6>Email</h6>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name='email'
                                            checked={optin.data.email.enable}
                                            onChange={handleChangeToogle}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                                {optin.data.email.enable ?
                                    <div className="input-outer">
                                        <input
                                            className="common-inp alt"
                                            type="text"
                                            placeholder="Enter Email"
                                            name="email"
                                            value={optin.data.email.text}
                                            onChange={handleChangeText}
                                        />
                                    </div>
                                    : null}
                                <hr />

                                <div className="widget-elements-title">
                                    <h6>Phone Number</h6>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name='phone'
                                            checked={optin.data.phone.enable}
                                            onChange={handleChangeToogle}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                                {optin.data.phone.enable ?
                                    <div className="input-outer">
                                        <input
                                            className="common-inp alt"
                                            type="text"
                                            placeholder="Enter phone number"
                                            name="phone"
                                            value={optin.data.phone.text}
                                            onChange={handleChangeText}
                                        />
                                    </div>
                                    : null}
                                <hr />

                                <div className="widget-elements-title">
                                    <h6>Review</h6>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name='review'
                                            checked={optin.data.review.enable}
                                            onChange={handleChangeToogle}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                                {optin.data.review.enable ?
                                    <div className="input-outer">
                                        <textarea
                                            className="common-inp alt"
                                            placeholder="Enter Your Review"
                                            name="review"
                                            value={optin.data.review.text}
                                            onChange={handleChangeText}
                                        />
                                    </div>
                                    : null}
                                <hr />
                                <div className="widget-elements-title">
                                    <h6>Autoresponder</h6>
                                </div>
                                <div className="input-outer">
                                    <select
                                        className="common-inp alt"
                                        name="autoresponderId"
                                        value={optin.data.autoresponderId}
                                        onChange={handleChange}
                                    >
                                        <option value={""}>Select</option>
                                        {autoresponder.length > 0 ?
                                            autoresponder.map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem.id}>{curElem.username}</option>
                                                )
                                            })
                                            : ""}
                                    </select>

                                </div>
                                <hr />


                                <div className="widget-elements-title">
                                    <h6>Widget Background</h6>
                                    <div className="d-flex">
                                        <div style={{ marginRight: "10px" }}>
                                            <span style={{ fontSize: "13px" }}> {optin.data.background.type === "color" ? "Color" : ""}</span>
                                        </div>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                name='type'
                                                onChange={(e) => handleChangeColor(e, "background")}
                                                checked={optin.data.background.type === "color" ? true : false}
                                            />
                                            <span className="slider"></span>
                                        </label>
                                        <div style={{ marginLeft: "10px" }}>
                                            <span style={{ fontSize: "13px" }}> {optin.data.background.type === "color" ? "" : "Image"}</span>
                                        </div>
                                    </div>
                                </div>
                                {optin.data.background.type === "color" ?
                                    <WidgetColor
                                        type={"color"}
                                        color={optin.data.background.src}
                                        handleChangeColor={handleChangeColor}
                                    />
                                    :
                                    <div className="input-outer " style={{ position: "relative", cursor: "pointer" }}>
                                        <div className="widget-upload ">
                                            <div style={{ paddingLeft: "26px", }}>
                                                {loader.upload ?
                                                    <i className='fa fa-spin fa-spinner' style={{ fontSize: 30 }} /> :
                                                    <div className='wd_upload_img'>

                                                        <input type="file" onChange={onLoadFile} />
                                                        <BsCloudUpload style={{ marginRight: "10px" }} />
                                                        Upload Image
                                                    </div>
                                                }
                                            </div>
                                            <div className='widget-img-box'>
                                                <img src={optin.data.background.src ? optin.data.background.src : dummyImage} />
                                            </div>
                                        </div>

                                    </div>
                                }

                                <hr />

                                <div className="widget-elements-title">
                                    <h6>Widget Border</h6>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            name='border'
                                            checked={optin.data.border.enable}
                                            onChange={handleChangeToogle}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                                {optin.data.border.enable &&
                                    <WidgetColor
                                        type={"border"}
                                        color={optin.data.border.color}
                                        handleChangeColor={handleChangeColor}
                                    />
                                }
                                <hr />


                                <div className="widget-elements-title">
                                    <h6>Widget Animation</h6>
                                </div>
                                <div className="input-outer">
                                    <select
                                        className="common-inp alt"
                                        name="animation"
                                        onChange={handleChange}
                                        value={optin.data.animation}
                                    >
                                        <option value="">None</option>
                                        {animData.length > 0 ?
                                            animData.map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem.name} className='text-capitalize'>{curElem.name.replace("animate__", "")}</option>
                                                )
                                            })
                                            : null}
                                    </select>
                                </div>

                                <hr />

                                <ImageSection
                                    type="optin"
                                    image={optin.data.image}
                                    handleChangeToogle={handleChangeToogle}
                                />

                                <hr />

                                <div className="widget-elements-title">
                                    <h6>Widget Position</h6>
                                </div>
                                <div className="input-outer">
                                    <select
                                        className="common-inp alt"
                                        name="position"
                                        value={optin.data.position}
                                        onChange={handleChange}
                                    >
                                        <option value="center">Center</option>
                                        <option value="top">Top</option>
                                        <option value="right">Right</option>
                                        <option value="b-right">Botton Right</option>
                                    </select>
                                </div>
                            </div>

                            <WindowStyle />

                            <FloatingWindow
                                handleClose={handleClose}
                            />
                        </div>
                    </Modal.Body>
                </Modal>
            </>
    )
}

export default OptionStyle;