import React from 'react'
import { useSelector } from 'react-redux'
import { appName } from '../../Global/Global'

const Wabinar = () => {
    const rebrand = useSelector(state => state.rebrand.data)
    return (
        <div className="webnair">
            <div className="webnair-in">
                <div className="webnair-main">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12 ">
                            <div className="webnair-left"><img alt="" src={require('../../assets/images/abhi.png')} /></div>
                        </div>
                        <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                            <div className="webnair-right">
                                <h5>{rebrand ? rebrand.name : appName} LESSON #1</h5>
                                <h6>Join Us for live webinar</h6>
                                <p>Join us for a private training that will walk you through how to use {rebrand ? rebrand.name : appName} to help you create and sell videos for maximum engagement, views and profits. This training or upgrade will not be made available afterwards. Sign up and make sure to show up on this live training with all your questions.</p>
                                <a className="theme-btn mt-4 w-205" href="https://aireputors.com/training" target='_blank'><span>Join Now</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Wabinar