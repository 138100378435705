import React, { useEffect, useState } from 'react'
import { BsFileEarmarkText } from "react-icons/bs";
import CreatePost from './CreatePost';
import PostRow from './PostRow';
import { useDispatch } from 'react-redux';
import { onFetchBusinessPosts } from '../../../../../Redux/Actions/ReviewAction';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const Post = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth)
    const location = useLocation()
    const { id, aId, locId, type } = queryString.parse(location.search)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (id, type) => {
        setShow(true)
        setModalType({
            id: id,
            type: type
        })
    }
    const [modalType, setModalType] = useState({
        id: -1,
        type: "create"
    });
    const [post, setPost] = useState([])
    const [loader, setLoader] = useState({
        fetch: false
    })

    const fetchPosts = () => {
        setLoader({ ...loader, fetch: true });
        let data = {
            locationId: locId,
            accountId: aId,
        };
        dispatch(onFetchBusinessPosts(data, setPost, loader, setLoader));
    }

    useEffect(() => {
        fetchPosts()
    }, [])

    return (
        <>
            {!show ?
                <div>
                    <h3><BsFileEarmarkText /> Posts</h3>
                    <div className='post-head'>
                        <h4>Offer Posts</h4>
                        {+auth.user.isClientAccount === 1 ? null :
                            <button className='theme-btn' onClick={() => handleShow(-1, "create")}><span>+ Add Post</span></button>
                        }
                    </div>
                    <div className='post-list'>
                        {post.length > 0 ?
                            post.map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <PostRow
                                            curElem={curElem}
                                            post={post}
                                            setPost={setPost}
                                            fetchPosts={fetchPosts}
                                            handleShow={handleShow}
                                        />
                                    </React.Fragment>
                                )
                            })
                            : <div className="text-center mt-5">
                                <span>{type === "facebook" ? `"Offer post" is not supported in Facebook` : "No post created yet."}</span>
                            </div>
                        }
                    </div>
                </div>
                : <CreatePost
                    type={modalType}
                    handleClose={handleClose}
                    fetchPosts={fetchPosts}
                />
            }
        </>
    )
}

export default Post
