import React, { useEffect, useState } from "react";
import TitleBar from "../../../CommonComponents/TitleBar";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import UploadBulk from "./UploadBulk";
import { onAddManualLead, onFetchLeadFolder } from "../../../../Redux/Actions/LeadAction";


const AddLeads = () => {

    const dispatch = useDispatch();
    const leadFolderData = useSelector((state) => state.lead.data);
    const [allFolder, setAllFolder] = useState([])
    const [state, setState] = useState({
        fId: "",
        name: "",
        emails: "",
        phoneNumber: ""
    });
    const [loader, setLoader] = useState({
        submit: false
    });

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({ ...state, [name]: value })
    }

    const fetchAllFolder = () => {
        dispatch({ type: "SET_LEAD_FOLDER_DATA", payload: { data: false, loader: true } })
        dispatch(onFetchLeadFolder())
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({ ...loader, submit: true })
        let data = { ...state }
        dispatch(onAddManualLead(data, setState, loader, setLoader, fetchAllFolder))
    }

    useEffect(() => {
        if (leadFolderData) {
            setAllFolder(leadFolderData)
        }
    }, [leadFolderData])

    return (
        <>
            <TitleBar title="Add Leads" />

            <div className="site-wrap">
                <div className="site-container">
                    <div className="widget-top">
                        <div className="widget-top-left">
                            <h6>Add leads</h6>
                        </div>
                    </div>

                    <div className="bulk-wrap">
                        <div className="row">
                            <div className="col-lg-5">
                                <form onSubmit={handleSubmit}>
                                    <h5>Add your lead manually</h5>
                                    <div className="input-outer mt-4">
                                        <select
                                            className="common-inp alt"
                                            name="fId"
                                            value={state.fId}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="">Select folder</option>
                                            {allFolder.length > 0 ?
                                                allFolder.map((curElem, index) => {
                                                    return (
                                                        <option key={index} value={curElem.fId}>{curElem.folderName}</option>
                                                    )
                                                })
                                                : ""
                                            }
                                        </select>
                                    </div>
                                    <div className="input-outer mt-4">
                                        <input
                                            className="common-inp alt"
                                            type="text"
                                            placeholder="Enter your name"
                                            name='name'
                                            value={state.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="input-outer mt-4">
                                        <input
                                            className="common-inp alt"
                                            type="email"
                                            placeholder="Enter your email"
                                            name='emails'
                                            value={state.emails}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="input-outer mt-4">
                                        <input
                                            className="common-inp alt"
                                            type="number"
                                            placeholder="Enter your phone number"
                                            name='phoneNumber'
                                            value={state.phoneNumber}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="input-outer mt-4">
                                        <button className="theme-btn full"> <span>{loader.submit ? <> Submitting <i className="fa fa-spinner fa-spin ms-1" /></> : "Submit"}</span></button>
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-1">
                                <div className="dividline">
                                    <span>or</span>
                                </div>

                            </div>


                            <UploadBulk
                                leadFolderData={allFolder}
                                fetchAllFolder={fetchAllFolder}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AddLeads;