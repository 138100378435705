import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import TitleBar from '../../../CommonComponents/TitleBar'
import Google_logo from '../../../../assets/images/integrations/google_icon.png'
import fb_logo from '../../../../assets/images/integrations/logo-facebook.svg'
import zillow_logo from '../../../../assets/images/integrations/ZILLOW.png'

import { Link, useNavigate } from 'react-router-dom'

const MainReviewInbox = () => {

    const socialYoutube = useSelector((state) => state.social)

    const [socialData, setSocialData] = useState([])
    const navigate = useNavigate();

    const handleClick = (id, type) => {
        navigate(`/review-inbox/review?id=${id}&type=${type}`)
    }


    useEffect(() => {
        if(socialYoutube){
            setSocialData(
                [...socialYoutube.google, ...socialYoutube.facebook, ...socialYoutube.zillow]
            )
        }
    },[socialYoutube])

    return (
        <>

            <TitleBar title="Review Inbox" />
            <div className="site-wrap">
                <div className="site-container">
                    <h4 className='my-2 mx-2'>Choose account</h4>

                    <div className="row">
                        {socialData.length > 0 ?
                            socialData.map((curElem, index) => {
                                return (
                                    <div key={index} className="col-lg-3 col-xl-2 col-md-6 col-12 mb-lg-0 mb-4" style={{ cursor: "pointer" }} onClick={() => handleClick(curElem.id, curElem.type)}>
                                        <div className="review_box">
                                            <div className="review_img">
                                                <img src={curElem.type === "google" ? Google_logo : curElem.type === "facebook" ?  fb_logo : zillow_logo} alt="" />
                                            </div>
                                            <div className="review_text_wrap">
                                                <p>{curElem.username}</p>
                                                <p>{curElem.created}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) :
                            <div className="text-center mt-5">
                                <span>No Review account is connected. Please go to the integration tab and connect.</span><br />
                                <Link to={"/integration"} className="theme-btn my-2"><span>Connect</span></Link>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainReviewInbox
