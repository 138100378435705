import React, { useEffect, useState } from 'react'
import { Badge, Modal } from 'react-bootstrap'
import { IoClose } from "react-icons/io5";
import { FiSmile } from "react-icons/fi";
import SocialReviewSection from './SocialReviewSection';
import WidgetColor from './WidgetColor';
import { fetchData, onUploadMedia } from '../../../../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';
import ImageSection from './ImageSection';
import FloatingWindow from './FloatingWindow';
import { useSelector } from 'react-redux';
import { onChange, onChangeBackgroundColor, onChangeBorderColor, onChangeText, onToggleCheck } from '../../../../../Redux/Actions/WidgetActions';
import WindowStyle from './Style/WindowStyle';
import { appName, themeColor } from '../../../../../Global/Global';
import { setAlert } from '../../../../../Redux/Actions/AlertActions';
import { BsCloudUpload } from 'react-icons/bs';
import Swal from 'sweetalert2';
import dummyImage from "../../../../../assets/images/dummy.jpg"

const WidgetModal = ({ show, handleClose, pos }) => {

    const dispatch = useDispatch()
    const widget = useSelector(state => state.widget.data)
    const [percent, setPercent] = useState(0)

    let allowedType = ['image/png', 'image/jpg', 'image/jpeg']
    const [animData, setAnimData] = useState([])

    const [loader, setLoader] = useState({
        upload: false
    })

    const handleChangeToogle = (e) => {
        const { name, checked } = e.target
        dispatch(onToggleCheck(name, checked))
    }

    const handleChangeText = (e) => {
        const { name, value } = e.target
        if (name === "headline" && value.length > 60) {
            dispatch(setAlert("Your field exceeds the maximum character limit.", "danger"))
        } else if (name === "description" && value.length > 150) {
            dispatch(setAlert("Your field exceeds the maximum character limit.", "danger"))
        } else {
            dispatch(onChangeText(name, value))
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onChange(name, value))
    }

    const handleChangeColor = (color, type) => {
        if (type === "color") {
            type = "background"
            color = {
                target: {
                    value: color
                }
            }
        }
        if (type === "border") {
            dispatch(onChangeBorderColor(color))
        } else if (type === "background") {
            dispatch(onChangeBackgroundColor(color))
        }
    }

    const selectImage = (url) => {
        let obj = {
            target: {
                name: "src",
                value: url
            }
        }
        handleChangeColor(obj, "background")
    }


    const onLoadFile = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0]
            if (allowedType.includes(file.type)) {
                if (file.size < 5000000) {
                    let formData = new FormData();
                    formData.append('file', file);
                    formData.append('upload_type', "images");
                    setLoader(true)
                    dispatch(onUploadMedia(formData, selectImage, loader, setLoader, setPercent, "image"))

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for images is 5MB!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }


    const fetchAnimation = () => {
        dispatch(fetchData("fetch-animation", {}, setAnimData, false, false, false, "shortDataLg"))
    }

    useEffect(() => {
        fetchAnimation()
    }, [])


    return (
        <Modal className="widgetModal" show={show} onHide={handleClose}>
            <Modal.Body>
                <div className="widget_elements_wrapper" style={{ position: 'relative' }}>
                    <div className="widget-elements">

                        <div className="widget-elements-title">
                            <div className="widgetModal-close3" style={{ position: "absolute", right: "0" }} onClick={handleClose}><IoClose /></div>
                            <h6>Widget Headline</h6>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    name='headline'
                                    checked={widget.data.headline.enable}
                                    onChange={handleChangeToogle}
                                />
                                <span className="slider"></span>
                            </label>
                        </div>
                        {widget.data.headline.enable ?
                            <div className="input-outer">
                                <input
                                    className="common-inp alt"
                                    type="text"
                                    name='headline'
                                    placeholder="Would you recommend us?"
                                    value={widget.data.headline.text}
                                    onChange={handleChangeText}
                                    rows={2}
                                />
                                {/* <Badge pill bg="secondary" className='input-filed-badges'>
                                   <FiSmile className='me-1'/> {widget.data.headline.text.length} / 60
                                </Badge> */}
                            </div>
                            : null}
                        <hr />

                        <div className="widget-elements-title">
                            <h6>Widget Description</h6>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    name='description'
                                    checked={widget.data.description.enable}
                                    onChange={handleChangeToogle}
                                />
                                <span className="slider"></span>
                            </label>
                        </div>
                        {widget.data.description.enable ?
                            <div className="input-outer">
                                <textarea
                                    className="common-inp alt"
                                    name="description"
                                    placeholder="Your feedback helps us improve! If you have a second, please leave a review on any of the platforms below. It only takes 30 seconds. Thank you!."
                                    value={widget.data.description.text}
                                    onChange={handleChangeText}
                                />
                                {/* <Badge pill bg="secondary" className='input-filed-badges'>
                                   <FiSmile className='me-1'/> {widget.data.description.text.length} / 150
                                </Badge> */}
                            </div> : null}

                        <hr />

                        <SocialReviewSection
                            type={"Google"}
                            data={widget.data.socials.google}
                            officialUrl={"https://support.google.com/business/answer/7035772?hl=en"}
                        />

                        <SocialReviewSection
                            type={"Bing"}
                            data={widget.data.socials.bing}
                            officialUrl={"https://www.bing.com/"}
                        />

                        <SocialReviewSection
                            type={"Angi"}
                            data={widget.data.socials.angi}
                            officialUrl={"https://www.angi.com/"}
                        />

                        <SocialReviewSection
                            type={"Zillow"}
                            data={widget.data.socials.zillow}
                            officialUrl={"https://www.zillow.com/"}
                        />

                        <SocialReviewSection
                            type={"Foursquare"}
                            data={widget.data.socials.foursquare}
                            officialUrl={"https://foursquare.com/"}
                        />

                        <SocialReviewSection
                            type={"Trustpilot"}
                            data={widget.data.socials.trustpilot}
                            officialUrl={"https://www.trustpilot.com/"}
                        />

                        <SocialReviewSection
                            type={"Tripadvisor"}
                            data={widget.data.socials.tripadvisor}
                            officialUrl={"https://www.tripadvisor.in/"}
                        />


                        <SocialReviewSection
                            type={"Shopify"}
                            data={widget.data.socials.shopify}
                            officialUrl={"https://www.shopify.in/"}
                        />


                        <SocialReviewSection
                            type={"Facebook"}
                            data={widget.data.socials.facebook}
                            officialUrl={undefined}
                            findData={[
                                "Open your business page.",
                                "Click on “Settings”.",
                                "Select “Templates and Tabs” from the left-side menu.",
                                "Scroll down until you see the “Reviews” tab. If you don’t have a review tab, click the “Add a Tab” button to add one.",
                                "Click the grey “Settings” button next to Reviews. Make sure “Show Reviews” is switched to the on position. Below, you’ll find your review link. Copy that link, and send it to your customers to start collecting valuable feedback!"
                            ]}
                        />

                        <SocialReviewSection
                            type={"Yelp"}
                            data={widget.data.socials.yelp}
                            officialUrl={undefined}
                            findData={[
                                `Log in to your Yelp business account. If you do not have one yet, <a href="https://biz.yelp.com/business_name_and_location" target="_blank">Click here</a> to create one.`,
                                "Click on the arrow next to your business name in the top left corner of your dashboard. You should see your public Yelp listing.",
                                "Click the red “Write a Review” button.",
                                `Copy the URL from your browser's address bar and paste it into the field inside ${appName}.`
                            ]}
                        />


                        <div className="widget-elements-title">
                            <h6>Widget Background</h6>
                            <div className='d-flex'>
                                <div style={{ marginRight: "10px" }}>
                                    <span style={{ fontSize: "13px" }}> {widget.data.background.type === "color" ? "Color" : ""}</span>
                                </div>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        name='type'
                                        onChange={(e) => handleChangeColor(e, "background")}
                                        checked={widget.data.background.type === "color" ? true : false}
                                    />
                                    <span className="slider"></span>
                                </label>
                                <div style={{ marginLeft: "10px" }}>
                                    <span style={{ fontSize: "13px" }}> {widget.data.background.type === "color" ? "" : "Image"}</span>
                                </div>
                            </div>
                        </div>
                        {widget.data.background.type === "color" ?
                            <WidgetColor
                                type={"color"}
                                color={widget.data.background.src}
                                handleChangeColor={handleChangeColor}
                            />
                            :
                            <div className="input-outer " style={{ position: "relative", cursor: "pointer" }}>
                                <div className="widget-upload ">
                                    <div style={{ paddingLeft: "26px", }}>
                                        {loader.upload ?
                                            <i className='fa fa-spin fa-spinner' style={{ fontSize: 30 }} /> :
                                            <div className='wd_upload_img'>

                                                <input type="file" onChange={onLoadFile} />
                                                <BsCloudUpload style={{ marginRight: "10px" }} />
                                                Upload Image
                                            </div>
                                        }
                                    </div>
                                    <div className='widget-img-box'>
                                        <img src={widget.data.background.src ? widget.data.background.src : dummyImage} />
                                    </div>
                                </div>

                            </div>
                        }


                        <hr />

                        <div className="widget-elements-title">
                            <h6>Widget Border</h6>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    name='border'
                                    checked={widget.data.border.enable}
                                    onChange={handleChangeToogle}
                                />
                                <span className="slider"></span>
                            </label>
                        </div>
                        {widget.data.border.enable ?
                            <WidgetColor
                                type={"border"}
                                color={widget.data.border.color}
                                handleChangeColor={handleChangeColor}
                            /> : null}
                        <hr />

                        <div className="widget-elements-title">
                            <h6>Widget Animation</h6>
                        </div>
                        <div className="input-outer">
                            <select
                                className="common-inp alt text-capitalize"
                                name="animation"
                                onChange={handleChange}
                            >
                                <option value={widget.data.animation}>{widget.data.animation}</option>
                                {animData.length > 0 ?
                                    animData.map((curElem, index) => {
                                        return (
                                            <option key={index} value={curElem.name} className='text-capitalize'>{curElem.name.replace("animate__", "")}</option>
                                        )
                                    })
                                    : null}
                            </select>
                        </div>

                        <hr />
                        <ImageSection
                            image={widget.data.image}
                            handleChangeToogle={handleChangeToogle}
                        />

                        <hr />

                        <div className="widget-elements-title">
                            <h6>Widget Position</h6>
                        </div>
                        <div className="input-outer">
                            <select
                                className="common-inp alt"
                                name="position"
                                value={widget.data.position}
                                onChange={handleChange}
                            >
                                <option value="center">Center</option>
                                <option value="top">Top</option>
                                <option value="right">Right</option>
                                <option value="b-right">Botton Right</option>
                            </select>
                        </div>
                    </div>

                    <WindowStyle />

                    <FloatingWindow
                        handleClose={handleClose}
                    />
                </div>

            </Modal.Body>
        </Modal >

    )
}

export default WidgetModal